<div class="nav-full" ndexClickOutside>
  <div class="nav-top">
    <div
      id="homeDiv"
      class="nav-header"
      [title]="menu.home.title"
      [ngClass]="{ highlighted: menu.home.config.selected }"
    >
      <img
        id="homeImage"
        (click)="onMenuClick(menu.home)"
        class="nav-icons"
        [src]="menu.home.src"
        alt="Home"
        title="Home"
      />
    </div>
    
    <div
      id="ordersDiv"
      class="nav-header"
      [title]="menu.order.title"
      [ngClass]="{ highlighted: menu.order.config.selected }"
    >
      <img
        id="ordersImage"
        (click)="onMenuClick(menu.order)"
        class="nav-icons"
        [src]="menu.order.src"
        alt="Orders"
        title="Orders"
      />
      <div
        class="nav-submenu shadow top-0"
        *ngIf="menu.order.config?.showChild"
      >
        <ul class="line-start">
          <li
            id="ordersLi{{ i }}"
            *ngFor="let subMenu of menu.order.children; let i = index"
            (click)="onSubMenuClick(subMenu)"
            [title]="subMenu.title"
          >
            {{ subMenu.title }}
          </li>
        </ul>
      </div>
    </div>

    <div
      id="logisticsDiv"
      class="nav-header"
      [title]="menu.logistic.title"
      [ngClass]="{ highlighted: menu.logistic.config.selected }"
    >
      <img
        id="logisticsImage"
        (click)="onMenuClick(menu.logistic)"
        class="nav-icons"
        [src]="menu.logistic.src"
        alt="Logistics"
        title="Logistics"
      />
      <div
        class="nav-submenu shadow top-0"
        *ngIf="menu.logistic.config?.showChild"
      >
        <ul class="line-start">
          <li
            id="logisticsLi{{ i }}"
            *ngFor="let subMenu of menu.logistic.children; let i = index"
            (click)="onSubMenuClick(subMenu)"
            [title]="subMenu.title"
          >
            {{ subMenu.title }}
          </li>
        </ul>
      </div>
    </div>

    <div
      id="inventoryDiv"
      class="nav-header"
      [title]="menu.inventory.title"
      [ngClass]="{ highlighted: menu.inventory.config.selected }"
    >
      <img
        id="inventoryImage"
        (click)="onMenuClick(menu.inventory)"
        class="nav-icons"
        [src]="menu.inventory.src"
        alt="Inventory"
        title="Inventory"
      />
    </div>

    <div
      id="reportDiv"
      class="nav-header"
      [title]="menu.report.title"
      [ngClass]="{ highlighted: menu.report.config.selected }"
    >
      <img
        id="reportImage"
        (click)="onMenuClick(menu.report)"
        class="nav-icons"
        [src]="menu.report.src"
        alt="Reports"
        title="Reports"
      />
      <div
        class="nav-submenu shadow top-0"
        *ngIf="menu.report.config?.showChild"
      >
        <ul class="line-start">
          <li
            id="reportLi{{ i }}"
            *ngFor="let subMenu of menu.report.children; let i = index"
            (click)="onSubMenuClick(subMenu)"
            [title]="subMenu.title"
          >
            {{ subMenu.title }}
          </li>
        </ul>
      </div>
    </div>
    <div
      id="settingDiv"
      class="nav-header"
      [title]="menu.setting.title"
      [ngClass]="{ highlighted: menu.setting.config.selected }"
    >
      <img
        id="settingImage"
        (click)="onMenuClick(menu.setting)"
        class="nav-icons"
        [src]="menu.setting.src"
        alt="Settings"
        title="Settings"
      />
    </div>

    <div
      id="administrationDiv"
      *ngIf="isAdmin()"
      class="nav-header"
      [title]="menu.administration.title"
      [ngClass]="{ highlighted: menu.administration.config.selected }"
    >
      <img
        id="administrationImage"
        (click)="onMenuClick(menu.administration)"
        class="nav-icons"
        [src]="menu.administration.src"
        alt="Administration"
        title="Administration"
      />
      <div
        class="nav-submenu shadow top-0"
        *ngIf="menu.administration.config?.showChild"
      >
        <ul class="line-start">
          <li
            id="administrationLi{{ i }}"
            *ngFor="let subMenu of menu.administration.children; let i = index"
            (click)="onSubMenuClick(subMenu)"
            [title]="subMenu.title"
          >
            {{ subMenu.title }}
          </li>
        </ul>
      </div>
    </div>

    <div
      id="contactUsDiv"
      class="nav-header"
      [title]="menu.contactUs.title"
      [ngClass]="{ highlighted: menu.contactUs.config.selected }"
    >
      <img
        id="contactUsImage"
        (click)="onMenuClick(menu.contactUs)"
        class="nav-icons"
        [src]="menu.contactUs.src"
        alt="Contact Us"
        title="Contact Us"
      />
    </div>
  </div>

  <div class="nav-bottom">
    <div
      id="logoutDiv"
      class="nav-header"
      [title]="menu.logout.title"
      [ngClass]="{ highlighted: menu.logout.config.selected }"
      (click)="onSignOut()"
    >
      <a id="logoutIcon">
        <i class="fa {{ menu.logout.src }} icon" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</div>
