Array.prototype.flatten = function(this: Array<any>, result = []): Array<any> {
  this.forEach((value) => {
    if (Array.isArray(value)) {
      value.flatten(result);
    } else {
      result.push(value);
    }
  });

  return result;
};

Array.prototype.move = (oldIndex: number, newIndex: number) => {
  while (oldIndex < 0) {
    oldIndex += this.length;
  }

  while (newIndex < 0) {
    newIndex += this.length;
  }

  if (newIndex >= this.length) {
    let k = newIndex - this.length;
    while (k-- + 1) {
      this.push(undefined);
    }
  }

  this.splice(newIndex, 0, this.splice(oldIndex, 1)[0]);
};

Array.prototype.toTotal = (
  propertyName: string,
  restrictZero: boolean = false
) => {
  const total = this.reduce(
    (t, item) =>
      t + (item[propertyName] ? item[propertyName].toString().toNumber() : 0),
    0
  );

  return restrictZero ? total || null : total;
};

Array.prototype.toTotalFloat = (
  propertyName: string,
  restrictZero: boolean = false,
  fractionDigits: number = 2
) => {
  const total = this.reduce(
    (t, item) =>
      t +
      (item[propertyName]
        ? item[propertyName].toString().toNumber(true, fractionDigits)
        : 0),
    0
  );

  return restrictZero ? total || null : total;
};
