import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';

import { CacheResolverService } from '../cache/cache-resolver.service';
import { BaseService } from '../services/base.service';

import { CacheConstant } from '../cache/cache.constant';

import { TypeRef } from './type-ref';
import { TypeGroup } from './type-group';
import { AppPreference } from './app-preference';
import { Message } from './message';

enum StaticPrimaryKeys {
  AppPreference = 'preferenceId',
  MessageRef = 'messageId',
  TypeGroup = 'typeGroupCode',
  TypeRef = 'typeCode',
  State = 'stateCode',
}

@Injectable({
  providedIn: 'root',
})
export class StaticService extends BaseService {
  /*********************Properties*********************/
  readonly appPreference: AppPreference;
  readonly message: Message;
  readonly typeRef: TypeRef;
  readonly typeGroup: TypeGroup;

  readonly loaded$: Subject<boolean> = new Subject<boolean>();
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(protected http: HttpClient, private cache: CacheResolverService) {
    super(http);

    this.appPreference = new AppPreference(
      CacheConstant.StaticData.AppPreferences,
      StaticPrimaryKeys.AppPreference,
      this.cache
    );

    this.message = new Message(
      CacheConstant.StaticData.Messages,
      StaticPrimaryKeys.MessageRef,
      this.cache
    );

    this.typeRef = new TypeRef(
      CacheConstant.StaticData.TypeRefs,
      StaticPrimaryKeys.TypeRef,
      this.cache
    );

    this.typeGroup = new TypeGroup(
      CacheConstant.StaticData.TypeGroups,
      StaticPrimaryKeys.TypeGroup,
      this.cache
    );
  }
  /*********************Constructor*********************/

}
