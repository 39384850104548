Number.prototype.toBoolean = (): boolean => {
  return this.valueOf() === 0 ? false : true;
};

Number.prototype.length = (): number => {
  return this.valueOf().toString().length;
};

Number.prototype.padStart = function(maxLength: number, fillNumber: number): string {
  let value = this.valueOf().toString();
  value = value.padStart(maxLength, fillNumber.toString());
  return value;
};

Number.prototype.restrictZero = (): number => {
  return (this.valueOf() as number) || null;
};
