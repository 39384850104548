export class StaticConstants {
  static readonly Properties = {
    TypeGroupDesc: 'typeGroupDesc',
    TypeCode: 'typeCode',
    TypeDesc: 'typeDesc',
    TypeFullDesc: 'typeFullDesc',
  };
}

export class AppPreferenceConstant {
  static readonly SEND_UI_CONSOLE_LOGS = 'SEND_UI_CONSOLE_LOGS';
}

export enum TypeGroupConstant {}

export class TypeRefConstant {
  static readonly UserType = {
    Internal_Admin: 101,
    Internal_Non_Admin: 102,
    External: 103
  };
}
