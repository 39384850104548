import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { UserVerificationModalComponent } from './user-verification-modal/user-verification-modal.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { DateTooltipDirective } from '../core/directives/date-tooltip.directive';
import { DateTooltipComponent } from './date-tooltip/date-tooltip.component';



@NgModule({
  declarations: [
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    UserVerificationModalComponent,
    NoAccessComponent,
    DateTooltipDirective,
    DateTooltipComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule],
  exports: [HeaderComponent, NavbarComponent, FooterComponent, DateTooltipComponent, DateTooltipDirective],
})
export class AppCommonModule {}
