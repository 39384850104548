export class OktaConstant {
  static readonly IdToken = 'id_token';
  static readonly AccessToken = 'access_token';
  static readonly TokenType = 'Bearer';

  static readonly Messages = {
    VerifyAuthentication: 'Verifying authentication',
    SigninIntoApp: 'Signing into NDEX',
    TokenValidationFailed: 'An error has occurred while validating token.',
  };
}
