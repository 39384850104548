import { AppEnv } from '../../src/app/core/constants/app.constant';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: AppEnv.Local,
  configUrl: '',
  production: false,
  baseUrl: 'https://nphsden-api-dev.apps.cf.np.gc.cahcloud.net/',
  baseAdminUrl: 'https://ndex-user-administration-api-dev.apps.cf.np.gc.cahcloud.net/',
  baseAuthUrl: 'https://nphsden-auth-api-dev.apps.cf.np.gc.cahcloud.net/'};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
