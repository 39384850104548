import { Component, OnInit } from '@angular/core';

import { UserService } from 'src/app/core/services/user.service';

import { AppConstant } from 'src/app/core/constants/app.constant';

@Component({
  selector: 'ndex-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  /*********************Properties*********************/
  readonly config = {
    title: null,
    backPageUrl: null,
    logoTitle: null,
  };

  private subscription = {
    pageHeader: null,
    backPageRoute: null,
  };

  userName: string;
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(private userService: UserService) {}
  /*********************Constructor*********************/

  /*********************Implementation Methods*********************/
  ngOnInit(): void {
    this.config.logoTitle = AppConstant.Title.Ndex;
  }

  getUser() {
    return this.userService.getDetails().name;
  }
}
