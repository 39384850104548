import * as _ from 'lodash';

import { CacheResolverService } from '../cache/cache-resolver.service';
import { CacheConstant } from '../cache/cache.constant';

export class StaticBase {
  /*********************Constructor*********************/
  constructor(
    private selector: string,
    private primaryKeyName: string,
    private cache: CacheResolverService
  ) {}
  /*********************Constructor*********************/

  /*********************Methods*********************/

  /**
   * Get all static data
   * @param staticDataName Specify static data name to get all data of it
   * @param sortBy Specify sort by key to sort the records. If not specified, sort by primary key by default.
   */
  getAll<T>(sortBy: string = null, staticDataName: string = null): Array<T> {
    const data = this.cache.get(CacheConstant.StaticData.Name);
    let staticData: any = null;

    if (data) {
      staticData = data[staticDataName || this.selector];
      staticData = _.sortBy(staticData, sortBy || this.primaryKeyName);
    }

    return staticData;
  }

  protected getData(): Array<any> {
    const data = this.cache.get(CacheConstant.StaticData.Name);
    let itemData: any = null;

    if (data) {
      itemData = data[this.selector];
    }

    return itemData;
  }

  /**
   * Update static data row(s) based on provided property name & value
   * @param propertyName Name of property to filter collection
   * @param propertyValue Value to match against provided property in collection
   */
  protected setItem(propertyName: string, propertyValue: any, object: any) {
    const data = this.getData();

    if (data) {
      const objectIndex = data.findIndex(
        (item) => item[propertyName] === propertyValue
      );

      if (objectIndex === -1) {
        data.push(object);
      } else {
        data[objectIndex] = object;
      }

      const staticdata = this.cache.get(CacheConstant.StaticData.Name);
      staticdata[this.selector] = data;
      this.cache.set(
        CacheConstant.StaticData.Name,
        staticdata
      );
    }
  }

  /**
   * Get filtered static data row(s) based on provided property name & value
   * @param propertyName Name of property to filter collection
   * @param propertyValue Value to match against provided property in collection
   */
  protected getItem(propertyName: string, propertyValue: any): any {
    let value: any = null;
    const data = this.getData();

    if (data) {
      const filteredData = data.filter(
        (item) =>
          item[propertyName] === propertyValue &&
          (item.activeFlag === undefined || item.activeFlag === true)
      );

      value = filteredData && filteredData.length > 0 ? filteredData[0] : value;
    }

    return value;
  }

  /*********************Methods*********************/
}
