<div mat-dialog-title>
  <h4 class="dialog-title {{ config.class }}">
    <em class="fas {{ data.message?.type }}"></em>{{ data.message?.title }}
  </h4>
</div>
<mat-dialog-content
  [innerHTML]="data.message?.message | sanitize: 'html'"
></mat-dialog-content>
<mat-dialog-actions class="text-right">
  <button
    type="button"
    class="button {{ data.message?.action.ok.theme }}"
    (click)="onOkClick()"
  >
    {{ data.message?.action.ok.text }}
  </button>
  <button
    type="button"
    class="button {{ data.message?.action.cancel.theme }}"
    (click)="onCancelClick()"
    *ngIf="data.message?.actions === actions.OkCancel"
  >
    {{ data.message?.action.cancel.text }}
  </button>
</mat-dialog-actions>
