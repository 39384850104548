import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';

import { BaseService } from './base.service';
import { UserService } from './user.service';
import { CacheResolverService } from '../cache/cache-resolver.service';

import { CacheConstant } from '../cache/cache.constant';
import { OktaRoutes } from '../constants/route.constant';
import { SignOutReason } from '../constants/app.constant';

import { User } from 'src/app/shared/models/user';
import { AuthValidate, OktaAuth } from '../core';
import { OktaPkceAuthService } from './okta-pkce-auth.service';
import { HttpService } from './http.service';
import { configData } from 'src/app/unit-testing/core/data/static.data';
import { ConfigApi } from '../constants/api.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  /*********************Properties*********************/
  signingOut = false;

  authenticated$: Subject<boolean> = new Subject();
  signedOut$: Subject<any> = new Subject();
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    protected http: HttpClient,
    private cacheService: CacheResolverService,
    private userService: UserService,
    private oktaService: OktaPkceAuthService,
  ) {
    super(http);
  }
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  userAuthenticated(): void {
    this.authenticated$.next(true);
  }

  isAuthenticated(): Promise<AuthValidate> {
    const oktaAuth = this.cacheService.get(CacheConstant.OktaAuth) as OktaAuth;
    const user = this.cacheService.get(CacheConstant.UserAuth) as User;
    const hasToken = oktaAuth && oktaAuth.accessToken ? true : false;
    const hasUserDetails =
      user && user.detail.userId ? true : false;
    const hasAuthDetails = hasToken && hasUserDetails;
    const response: AuthValidate = {
      authenticated: {
        app: hasAuthDetails,
        okta: false,
        all: false,
      },
    };

    if (hasAuthDetails && this.userService.isInternalUser()) {
      response.authenticated.okta = response.authenticated.all = true;
    }

    return Promise.resolve(response);
  }

  clearSession(): void {
    this.clearSessionCache();
  }

  /*********************Utility Methods*********************/

  /*********************Service Methods*********************/

  signOut(reason: SignOutReason = SignOutReason.Default) {
    this.signingOut = true;
    const isInternalUser = this.userService.isInternalUser();

    if (isInternalUser) {
      this.signOutInternalUser(reason);
    } else {
      this.isAuthenticated().then((authResponse) => {
        this.signOutCustomer(reason);
      });
    }
  }

  authenticate(data: any) {
    const url = this.serviceUrl(
      configData.issuer,
      ConfigApi.auth
    );

    return super.post(url, data);
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private signOutInternalUser(reason: SignOutReason): void {
    let redirectUrl = null;

    if (reason !== SignOutReason.Default) {
      redirectUrl =
        reason === SignOutReason.SessionExpired
          ? OktaRoutes.SessionExpired.Url
          : OktaRoutes.SessionTimeout.Url;
    }

    this.signOutUser(reason, redirectUrl);
  }

  private signOutCustomer(reason: SignOutReason): void {
    this.signOutUser(reason);
  }

  private signOutUser(reason: SignOutReason, redirectUrl: string = null): void {
    this.clearSessionCache();
    this.oktaService.signOut();
  }

  private clearSessionCache(): void {
    this.cacheService.remove(CacheConstant.OktaAuth);
    this.cacheService.remove(CacheConstant.UserAuth);
  }

  /*********************Private Methods*********************/
}
