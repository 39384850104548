import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorRoutingModule } from './error-routing.module';

import { ErrorComponent } from './error/error.component';

import { ErrorService } from './error.service';

import { ErrorsHandler } from './errors-handler';

@NgModule({
  declarations: [ErrorComponent],
  imports: [CommonModule, ErrorRoutingModule],
  providers: [
    ErrorService,
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
  ],
})
export class ErrorModule {}
