const CircularJSON = require('circular-json');

Object.clone = (obj: any): any => {
  return CircularJSON.parse(CircularJSON.stringify(obj));
};

Object.parse = (obj): any => {
  return CircularJSON.parse(obj);
};

Object.stringify = (obj): any => {
  return CircularJSON.stringify(obj);
};

Object.mapProperties = (dest: any, src: any) => {
  for (const property in src) {
    if (src.hasOwnProperty(property) && dest[property] !== undefined) {
      dest[property] = src[property];
    }
  }
};

Object.hasValue = (obj): any => {
  return Object.keys(obj).find((key) => obj[key]) !== undefined;
};
