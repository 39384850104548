import { ApiConfig, AppConfig as ApplicationConfig, OktaConfig } from '../core';

export class Environment {
  /*********************Properties*********************/
  get appConfig(): ApplicationConfig {
    return this.applicationConfiguration;
  }

  set appConfig(config: ApplicationConfig) {
    this.applicationConfiguration = config;
  }

  get apiConfig(): ApiConfig {
    return this.apiConfiguration;
  }

  set apiConfig(config: ApiConfig) {
    this.apiConfiguration = config;
  }

  get oktaConfig(): OktaConfig {
    return this.oktaConfiguration;
  }

  set oktaConfig(config: OktaConfig) {
    this.oktaConfiguration = config;
  }

  private static instance: Environment;

  private applicationConfiguration: ApplicationConfig = {
    appTimeOut: 15,
    apigeeTokenEndpoint: null,
    exportLimit: 0,
    featureFlag: false
  };

  private apiConfiguration: ApiConfig = {
    base: null,
    admin: null
  };

  private oktaConfiguration: OktaConfig = {
    clientId: null,
    issuer: null,
    scope: null,
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  private constructor() {}
  /*********************Constructor*********************/

  /*********************Methods*********************/

  static getInstance() {
    if (!Environment.instance) {
      Environment.instance = new Environment();
    }

    return Environment.instance;
  }

  /*********************Methods*********************/
}
