import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  OnInit
} from '@angular/core';
import * as moment from 'moment';
import { DateTooltipComponent } from 'src/app/common/date-tooltip/date-tooltip.component';
import { TimezoneMap, TooltipPosition } from '../constants/app.constant';

@Directive({
  selector: '[ndexUiDateTooltip]',
})
export class DateTooltipDirective implements OnInit {
  @Input() ndexUiDateTooltip = '';
  @Input() date;
  @Input() timezones;
  @Input() position: TooltipPosition = TooltipPosition.DEFAULT;
  @Input() textFlag;

  private componentRef: ComponentRef<any> | null = null;
  format = 'MM/DD/yyyy HH:mm';

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) { }

  ngOnInit() {
    if (!this.date) {
      return;
    }

    this.elementRef.nativeElement.style.color = '#02B3E6';
    this.elementRef.nativeElement.style.textDecoration = 'underline';
    this.elementRef.nativeElement.style.fontWeight = 'bold';
    this.elementRef.nativeElement.style.cursor = 'pointer';
    if (this.textFlag) {
      this.elementRef.nativeElement.innerText = moment(new Date(this.date), this.format).tz('GMT').format(this.format) + ' ' + 'GMT';
    } else {
      this.elementRef.nativeElement.value = moment(new Date(this.date), this.format).tz('GMT').format(this.format) + ' ' + 'GMT';
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if ((this.componentRef === null) && this.date) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          DateTooltipComponent
        );
      this.componentRef = componentFactory.create(this.injector);

      this.appRef.attachView(this.componentRef.hostView);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);

      this.setTooltipComponentProperties();
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      const { left, right, top, bottom } =
        this.elementRef.nativeElement.getBoundingClientRect()
        this.componentRef.instance.position = this.position;

        switch (this.position) {
          case TooltipPosition.BELOW: {
            this.componentRef.instance.left = Math.round((right - left) / 2 + left);
            this.componentRef.instance.top = Math.round(bottom);
            break;
          }
          case TooltipPosition.ABOVE: {
            this.componentRef.instance.left = Math.round((right - left) / 2 + left);
            this.componentRef.instance.top = Math.round(top);
            break;
          }
          case TooltipPosition.RIGHT: {
            this.componentRef.instance.left = Math.round(right);
            this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
            break;
          }
          case TooltipPosition.LEFT: {
            this.componentRef.instance.left = Math.round(left);
            this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
            break;
          }
          default: {
            break;
          }
        }

      this.componentRef.instance.fieldName = this.ndexUiDateTooltip;
      this.componentRef.instance.custTime = this.timezones.cust?
        moment(new Date(this.date), this.format)
          .tz(TimezoneMap[this.timezones.cust])
          .format(this.format) +
        ' ' +
        this.timezones.cust: this.timezones.defaultText;
      this.componentRef.instance.dispTime = this.timezones.disp?
        moment(new Date(this.date), this.format)
          .tz(TimezoneMap[this.timezones.disp])
          .format(this.format) +
        ' ' +
        this.timezones.disp: this.timezones.defaultText;
      this.componentRef.instance.delTime = this.timezones.del
        ? moment(new Date(this.date), this.format)
            .tz(TimezoneMap[this.timezones.del])
            .format(this.format) +
          ' ' +
          this.timezones.del
        : this.componentRef.instance.dispTime;
      this.componentRef.instance.pharmTime = this.timezones.pharm
        ? moment(new Date(this.date), this.format)
            .tz(TimezoneMap[this.timezones.pharm])
            .format(this.format) +
          ' ' +
          this.timezones.pharm: '';
      this.componentRef.instance.localTime =
        moment(new Date(this.date), this.format)
        .tz(moment.tz.guess())
        .format(this.format) +
        ' ' +
        moment(new Date('01/01/2000')).tz(moment.tz.guess()).zoneName();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
