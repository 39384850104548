String.prototype.toPascalCase = (): string => {
  return this.replace(/(\w)(\w*)/g, (g0, g1, g2) => {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

String.prototype.toBoolean = (): boolean => {
  const value = this.valueOf().toString();

  if (String.isNullOrEmpty(value)) {
    return false;
  } else if (
    value.toLowerCase() === 'true' ||
    value.toLowerCase() === '1' ||
    value.toLowerCase() === 'y' ||
    value.toLowerCase() === 'yes'
  ) {
    return true;
  }

  return false;
};

String.prototype.equals = (
  match: string,
  ignoreCase: boolean = true
): boolean => {
  return ignoreCase
    ? this.valueOf().toString().toLowerCase() === match.toString().toLowerCase()
    : this.valueOf().toString() === match;
};

String.prototype.toKeyValuePair = () => {
  const array = this.valueOf().toString().split('&');
  const data = {};

  for (const obj of array) {
    const value = array[obj].split('=');
    data[value[0]] = value[1];
  }

  return data;
};

String.isNullOrEmpty = (val: any): boolean => {
  if (
    val === undefined ||
    val === null ||
    val.trim() === '' ||
    val.trim().toLowerCase() === 'null'
  ) {
    return true;
  }
  return false;
};
