import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import jwtDecode from 'jwt-decode';

import { BaseService } from './base.service';
import { CacheResolverService } from '../cache/cache-resolver.service';

import { CacheConstant } from '../cache/cache.constant';
import { CacheDuration } from '../constants/app.constant';
import { OktaRoutes } from '../constants/route.constant';
import { UserProperty } from '../constants/user.constant';

import { User, UserDetail } from 'src/app/shared/models/user';
import { TypeRefConstant } from '../static/static.constant';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  /*********************Properties*********************/
  userProfileLoaded$: Subject<boolean> = new Subject<boolean>();

  private userDetail: UserDetail = null;

  private get detail(): UserDetail {
    if (!this.userDetail || !this.userDetail.userId) {
      const user: User = this.cacheService.get(CacheConstant.UserAuth);

      this.userDetail =
        user && user[UserProperty.Detail]
          ? user[UserProperty.Detail]
          : this.getDefaultUserDetail();
    }

    return this.userDetail;
  }
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    protected http: HttpClient,
    private readonly cacheService: CacheResolverService
  ) {
    super(http);
  }
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  init(): void {
    const token = this.cacheService.get(CacheConstant.OktaAuth);

    if (token.accessToken) {
      this.onUserDetailsLoaded(token.accessToken);
      this.userProfileLoaded$.next(true);
    }
  }

  getProperty(property: UserProperty): any {
    const user: User = this.cacheService.get(CacheConstant.UserAuth);
    let value = null;

    if (user && user[property]) {
      value = user[property];
    }

    return value;
  }

  getDetails(): UserDetail {
    return this.detail;
  }

  getRoles(): number[] {
    const roles = this.getProperty(UserProperty.Roles);
    return roles? roles: [];
  }

  isAdmin(): boolean {
    const details = this.getDetails();
    return details.config.admin;
  }

  isInternalUser(): boolean {
    const details = this.getDetails();
    return details.config.internal;
  }

  getLogoutUrl(): string {
    return OktaRoutes.Logout.Url;
  }

  /*********************Utility Methods*********************/

  /*********************Private Methods*********************/

  private getDefaultUserDetail(): UserDetail {
    return {
      emailVerified: false,
      emailId: '',
      firstName: '',
      lastName: '',
      loginId: '',
      name: '',
      timezone: '',
      userId: null,
      config: { admin: false, internal: false },
    };
  }

  private onUserDetailsLoaded(token: string): User {
    const user: User = {
      detail: null,
      roles: []
    };

    const userToken = jwtDecode<any>(token);
    const claims = JSON.parse(userToken.user_claims);
    const info = JSON.parse(userToken.userinfo);

    user.roles = claims.roleNums? claims.roleNums: [];
    user.detail = {
      emailId: info.email,
      emailVerified: info.email_verified,
      firstName: claims.firstName,
      lastName: claims.lastName,
      loginId: info.preferred_username,
      name: claims.firstName + ' ' + claims.lastName,
      timezone: info.zoneinfo,
      userId: info.sub
    };

    user.detail.config = {
      admin: user.roles.includes(TypeRefConstant.UserType.Internal_Admin),
      internal: user.roles.includes(TypeRefConstant.UserType.Internal_Admin) || user.roles.includes(TypeRefConstant.UserType.Internal_Non_Admin)
    };

    this.cacheService.set(CacheConstant.UserAuth, user);
    return user;
  }

  /*********************Private Methods*********************/
}
