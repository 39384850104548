import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';

import { BaseService } from './core/services/base.service';
import { TokenService } from './core/services/token.service';
import { AuthService } from './core/services/auth.service';
import { UserService } from './core/services/user.service';
import { OktaPkceAuthService } from './core/services/okta-pkce-auth.service';

import { AuthValidate } from './core/core';

@Injectable({
  providedIn: 'root',
})
export class AppService extends BaseService {
  /*********************Properties*********************/
  private queryToken: string = null;
  /*********************Properties*********************/

  /*********************Events/Observables*********************/
  pageHeader$: Subject<string> = new Subject<string>();
  backPageRoute$: Subject<string> = new Subject<string>();
  /*********************Events/Observables*********************/

  /*********************Constructor*********************/
  constructor(
    protected http: HttpClient,
    private authService: AuthService,
    private oktaPkceAuth: OktaPkceAuthService,
    private userService: UserService,
    private token: TokenService
  ) {
    super(http);
  }
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  init(): void {
    this.oktaPkceAuth.init();
  }

  initOnLoad(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (
        this.token.getQueryToken() ||
        this.userService.isInternalUser()
      ) {
        resolve(true);
        return;
      }

      this.authService.isAuthenticated().then((response: AuthValidate) => {
        resolve(response.authenticated.all);
      });
    });
  }

  /*********************Utility Methods*********************/

  /*********************Service Methods*********************/

  load(): Promise<any> {
    return new Promise((resolve, reject) => {

          this.oktaPkceAuth.auth.validate = false;

          this.userService.init();
          this.authService.userAuthenticated();

          resolve(true);
    });
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  /*********************Private Methods*********************/
}
