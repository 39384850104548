import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { PaginatorComponent } from '../directives/paginator/paginator.component';
import { MaterialModule } from './material.module';
import { CustomValidatorDirective } from '../directives/custom-validator.directive';

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule],
  declarations: [ClickOutsideDirective, PaginatorComponent, CustomValidatorDirective],
  exports: [ClickOutsideDirective, PaginatorComponent, CustomValidatorDirective],
})
export class DirectiveModule {}
