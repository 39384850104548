import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToastrModule } from 'ngx-toastr';
import { InputMaskModule } from '@ngneat/input-mask';
import { MaterialModule } from './modules/material.module';
import { DirectiveModule } from './modules/directives.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { ToastService } from './utils/toast.service';
import { EncDecService } from './utils/enc-dec.service';
import { LoggerService } from './utils/logger.service';
import { UtilityService } from './utils/utility.service';
import { RouteUtilService } from './utils/route-util.service';
import { NgChartsModule } from 'ng2-charts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    InputMaskModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgChartsModule,
    NgxMatSelectSearchModule,
    MaterialModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    DirectiveModule,
    ReactiveFormsModule,
    ToastrModule,
    MaterialModule,
    InputMaskModule,
    NgMultiSelectDropDownModule,
    NgChartsModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    ToastService,
    EncDecService,
    LoggerService,
    UtilityService,
    RouteUtilService,
  ],
})
export class SharedModule {}
