import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';

@Injectable()
export class RouteUtilService {
  /*********************Properties*********************/
  get previousUrl(): string {
    return this.prevUrl;
  }

  get activeUrl(): string {
    return this.currUrl;
  }

  private prevUrl = null;
  private currUrl = null;
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.prevUrl = this.currUrl;
        this.currUrl = e.url;
      });
  }
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  navigate(url) {
    this.router.navigateByUrl(url, { replaceUrl: true });
  }

  /*********************Utility Methods*********************/
}
