import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipeModule } from 'src/app/shared/modules/pipe.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';

@NgModule({
    declarations: [ConfirmDialogComponent, MessageDialogComponent],
    imports: [CommonModule, MaterialModule, PipeModule]
})
export class DialogModule {}
