import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';
import { CacheResolverService } from '../../core/cache/cache-resolver.service';

import { HttpHeader, CacheDuration } from '../../core/constants/app.constant';
import { CacheConstant } from 'src/app/core/cache/cache.constant';
import { OktaAuth } from '../core';
import { IResponse } from 'src/app/shared/models/app';
import { Environment } from '../environment/environment.model';

@Injectable({
  providedIn: 'root',
})
export class TokenService extends BaseService {
  /*********************Properties*********************/
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(http: HttpClient, private readonly cache: CacheResolverService) {
    super(http);
  }
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  get(): OktaAuth {
    return this.getAuthToken();
  }

  set(
    token: string,
    tokenType: string = 'Bearer',
    expiresAt: Date = null
  ): void {
    expiresAt = expiresAt || this.addHours(new Date(), 2);

    const oktaAuth = this.getAuthToken();
    oktaAuth.accessToken = token;
    oktaAuth.tokenType = tokenType;
    oktaAuth.expiresAt = expiresAt;

    this.cache.set(CacheConstant.OktaAuth, oktaAuth);
  }

  getQueryToken() {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParamToken = urlParams.get(HttpHeader.Token);
    return queryParamToken;
  }

  exist() {
    const queryParamToken = this.getQueryToken();
    const oktaAuthCache = this.get();
    const token = queryParamToken || oktaAuthCache.accessToken;

    if (queryParamToken) {
      this.set(token);
    }

    return token;
  }

  /*********************Utility Methods*********************/

  /*********************Service Methods*********************/

  validate(accessToken: string, idToken: string) {
    const options = {
      headers: {
        Authorization: `Bearer ${idToken}, Bearer ${accessToken}`,
      },
    };

    return super
      .post(
        Environment.getInstance().appConfig.apigeeTokenEndpoint,
        null,
        options
      )
      .pipe(
        map((response: IResponse) => {
          if (!response.hasError) {
            this.set(response.data.access_token, response.data.token_type);
          }

          return response;
        })
      );
  }

  addHours(date: Date, hours: number): Date {
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    return date;
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private getAuthToken(): OktaAuth {
    let oktaAuth = this.cache.get(CacheConstant.OktaAuth) as OktaAuth;

    if (!oktaAuth) {
      oktaAuth = {
        accessToken: null,
        tokenType: null,
        expiresAt: null,
      };
    }

    return oktaAuth;
  }

  /*********************Private Methods*********************/
}
