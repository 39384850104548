<div class="paginator flex">
  <div class="per-page flex">
    <span class="no-wrap">
      Items per page:
    </span>
    <mat-form-field class="page per-page">
      <mat-select
        [(ngModel)]="paginatorConfig.perPage"
        (ngModelChange)="pagifyData()"
      >
        <mat-option
          *ngFor="let pageSize of paginatorConfig.pageSizes"
          [value]="pageSize"
        >
          {{ pageSize }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="pages flex">
    <mat-form-field class="page-no per-page">
      <mat-select
        [(ngModel)]="paginatorConfig.currentPage"
        (ngModelChange)="pagifyData()"
      >
        <mat-option
          *ngFor="let page of pageNumbers; let i = index"
          [value]="i + 1"
        >
          {{ i + 1 }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span class="no-wrap">
      of {{ paginatorConfig.totalPages }} page(s)
    </span>
  </div>

  <div class="page-buttons">
    <button
      mat-icon-button
      [disabled]="paginatorConfig.currentPage === 1"
      (click)="changePage(paginatorConfig.currentPage - 1)"
      aria-label="Previous Page"
    >
      <i class="fa fa-angle-left" aria-hidden="true"></i>
    </button>
    <button
      mat-icon-button
      [disabled]="paginatorConfig.currentPage === paginatorConfig.totalPages"
      (click)="changePage(paginatorConfig.currentPage + 1)"
      aria-label="Next Page"
    >
      <i class="fa fa-angle-right" aria-hidden="true"></i>
    </button>
  </div>
</div>
