import { AppError } from './app-error';

export class ClientError extends AppError {
  constructor(error: string = '', log: boolean = true) {
    super();
    this.message = error;
    this.errorId = new Date().getTime();
    this.log = log;
  }

  log: boolean;
}
