import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from 'src/app/core/services/base.service';
import { UtilityService } from './utility.service';

import { LogLevel } from '../../core/constants/app.constant';

@Injectable({
  providedIn: 'root',
})
export class LoggerService extends BaseService {
  /*********************Properties*********************/
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(protected http: HttpClient, private utility: UtilityService) {
    super(http);
  }
  /*********************Constructor*********************/

  /*********************Service Methods*********************/

  trace(msg: any, additional: any[] = []): void {
    this.logMessage(LogLevel.TRACE, msg, additional);
  }

  debug(msg: any, additional: any[] = []): void {
    this.logMessage(LogLevel.DEBUG, msg, additional);
  }

  info(msg: any, additional: any[] = []): void {
    this.logMessage(LogLevel.INFO, msg, additional);
  }

  log(msg: any, additional: any[] = []): void {
    this.logMessage(LogLevel.LOG, msg, additional, true);
  }

  warn(msg: any, additional: any[] = []): void {
    this.logMessage(LogLevel.WARN, msg, additional, true);
  }

  error(msg: any, additional: any[] = []): void {
    this.logMessage(LogLevel.ERROR, msg, additional, true);
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private logMessage(
    level: LogLevel,
    message: any,
    additional: any[] = [],
    log: boolean = false
  ): void {
    switch (level) {
      case LogLevel.TRACE:
        console.trace(message, additional);
        break;

      case LogLevel.DEBUG:
        console.debug(message, additional);
        break;

      case LogLevel.INFO:
        console.info(message, additional);
        break;

      case LogLevel.WARN:
        console.warn(message, additional);
        break;

      case LogLevel.ERROR:
        console.error(message, additional);
        break;

      case LogLevel.LOG:
      default:
        console.log(message, additional);
        break;
    }

  }

  /*********************Private Methods*********************/
}
