import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from '../services/base.service';
import { AppService } from 'src/app/app.service';

import { Environment } from './environment.model';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService extends BaseService {
  /*********************Properties*********************/
  private environment: Environment = Environment.getInstance();
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(protected http: HttpClient, private appService: AppService) {
    super(http);
  }
  /*********************Constructor*********************/

  /*********************Service Methods*********************/

  getConfig(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
      .get('/config')
        .subscribe((response: any) => {
          const configData = response;

          if (configData.googleMapsAPIKey) {
            this.addGoogleMapsLib(configData.googleMapsAPIKey);
          }

          this.environment.oktaConfig = {
            clientId: configData.clientId,
            issuer: configData.issuer,
            scope: 'openid email',
          };

          this.environment.apiConfig = {
            base: configData.baseUrl,
            admin: configData.baseAdminUrl
          };

          this.environment.appConfig = {
            appTimeOut: configData.apiRequestTimeout,
            apigeeTokenEndpoint: configData.apigeeTokenEndPoint,
            exportLimit: configData.exportLimit,
            ddqUploadLimit: configData.ddqUploadLimit,
            featureFlag: configData.featureFlag === 'Yes'
          };

          this.appService.init();

          return this.appService.initOnLoad().then((load) => {
            if (load) {
              this.appService.load().then(() => {
                resolve(true);
              });
            } else {
              resolve(true);
            }

            return resolve(false);
          });
        });
    });
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private addGoogleMapsLib(key: string) {
    let myScript = document.createElement("script");
    myScript.setAttribute("src", "https://maps.googleapis.com/maps/api/js?key=" + key);
    myScript.setAttribute("async", "false");

    let head = document.head;
    head.insertBefore(myScript, head.firstElementChild);
  }

  /*********************Private Methods*********************/
}
