<div class="tooltip" [ngClass]="['tooltip--'+position]" [style.left]="left + 'px'" [style.top]="top + 'px'">
  <strong>{{fieldName}}</strong>
  <div *ngIf="custTime" class="no-wrap">
    Customer Time Zone: <strong class="time-color">{{custTime}}</strong>
  </div>
  <div *ngIf="dispTime" class="no-wrap">
    Dispensing Pharmacy Time Zone: <strong class="time-color">{{dispTime}}</strong>
  </div>
  <div *ngIf="delTime" class="no-wrap">
    Delivering Pharmacy Time Zone: <strong class="time-color">{{delTime}}</strong>
  </div>
  <div *ngIf="pharmTime" class="no-wrap">
    Pharmacy Time Zone: <strong class="time-color">{{pharmTime}}</strong>
  </div>
  <div class="no-wrap">
    <strong>Local Time Zone: {{localTime}}</strong>
  </div>
</div>
