export const RouteNames = {
  Error: 'error',
  Callback: 'auth-code/callback',
  Logout: 'logout',
  SessionExpired: 'session-expired',
  SessionTimeout: 'session/time-out',
  UnAuthorized: 'access-denied',
  OrderDetail: 'order-detail',
  OrderStatus: 'order-status',
  CustomerData: 'customer-data',
  ProductData: 'product-data',
  LocationData: 'location-data',
  Logistics: 'logistics',
  Dashboard: 'dashboard',
  home: 'home',
  UserAdmin: 'user-admin',
  ShipToAdmin: 'ship-to',
  RequestReports: 'request-reports',
  DownloadReports: 'download-reports',
  InvoiceReports: 'invoice-reports',
  OrderEmailNotifications: 'order-email-notificatons',
};

export enum MasterRoutes {
  Okta = '',
  Order = '',
  Logistics = '',
  Login = 'login',
  Inventory = 'inventory',
  Home = '',
  Admin = '',
  Reports = '',
  Settings = '',
  Callback = 'auth-code/callback',
  ContactUs = 'contact-us',
  NoAccess = 'no-access'
}

export const ErrorRoutes = {
  Error: {
    Name: RouteNames.Error,
    Url: RouteNames.Error,
  },
};

export const OktaRoutes = {
  Callback: {
    Name: RouteNames.Callback,
    Url: MasterRoutes.Okta + '/' + RouteNames.Callback,
  },
  Logout: {
    Name: RouteNames.Logout,
    Url: MasterRoutes.Okta + '/' + RouteNames.Logout,
  },
  SessionExpired: {
    Name: RouteNames.SessionExpired,
    Url: MasterRoutes.Okta + '/' + RouteNames.SessionExpired,
  },
  SessionTimeout: {
    Name: RouteNames.SessionTimeout,
    Url: MasterRoutes.Okta + '/' + RouteNames.SessionTimeout,
  },
  UnAuthorized: {
    Name: RouteNames.UnAuthorized,
    Url: MasterRoutes.Okta,
  },
};

export const OrderRoutes = {
  OrderDetail: {
    Name: RouteNames.OrderDetail,
    Url: MasterRoutes.Order + '/' + RouteNames.OrderDetail,
  },
  OrderStatus: {
    Name: RouteNames.OrderStatus,
    Url: MasterRoutes.Order + '/' + RouteNames.OrderStatus,
  },
  CustomerData: {
    Name: RouteNames.CustomerData,
    Url: MasterRoutes.Order + '/' + RouteNames.CustomerData,
  },
  ProductData: {
    Name: RouteNames.ProductData,
    Url: MasterRoutes.Order + '/' + RouteNames.ProductData,
  },
  LocationData: {
    Name: RouteNames.LocationData,
    Url: MasterRoutes.Order + '/' + RouteNames.LocationData,
  },
};

export const LogisticsRoutes = {
  Logistics: {
    Name: RouteNames.Logistics,
    Url: MasterRoutes.Logistics + '/' + RouteNames.Logistics,
  },
  Dashboard: {
    Name: RouteNames.Dashboard,
    Url: MasterRoutes.Logistics + '/' + RouteNames.Dashboard,
  },
};

export const AdminRoutes = {
  UserAdmin: {
    Name: RouteNames.UserAdmin,
    Url: MasterRoutes.Admin + '/' + RouteNames.UserAdmin,
  },
  ShipToAdmin: {
    Name: RouteNames.ShipToAdmin,
    Url: MasterRoutes.Admin + '/' + RouteNames.ShipToAdmin,
  },
};

export const ReportRoutes = {
  RequestReports: {
    Name: RouteNames.RequestReports,
    Url: MasterRoutes.Reports + '/' + RouteNames.RequestReports,
  },
  DownloadReports: {
    Name: RouteNames.DownloadReports,
    Url: MasterRoutes.Reports + '/' + RouteNames.DownloadReports,
  },
  InvoiceReports: {
    Name: RouteNames.InvoiceReports,
    Url: MasterRoutes.Reports + '/' + RouteNames.InvoiceReports,
  },
};

export const HomeRoutes = {
  Dashboard: {
    Name: RouteNames.home,
    Url: MasterRoutes.Home + '/' + RouteNames.home,
  },
};

export const SettingRoutes = {
  OrderEmailNotifications: {
    Name: RouteNames.OrderEmailNotifications,
    Url: MasterRoutes.Settings + '/' + RouteNames.OrderEmailNotifications,
  },
};