export const StaticData = {
  appPreferencesList: [
    {
      activeFlag: true,
      appTypeCode: -1,
      loginFlag: true,
      preferenceDesc: 'apigeeTokenEndpoint',
      preferenceId: 'apigeeTokenEndpoint',
      preferenceValue:
        'https://api.stage.cardinalhealth.com/oauth2/v2/token/jwtforoktab2b',
    },
  ],
  messageRefList: [
    {
      activeFlag: true,
      appTypeCode: -1,
      messageDescription: 'Seller not found',
      messageId: 'SEL01',
      messageTypeCode: 200,
    },
  ],
  stateList: [{ stateCode: 'OH', stateDescription: 'Ohio' }],
  typeGroupRefList: [
    {
      activeFlag: true,
      appTypeCode: -1,
      typeGroupCode: 1,
      typeGroupDesc: 'App Type',
    },
  ],
  typeRefList: [
    {
      activeFlag: true,
      typeCode: 200,
      typeDesc: 'Error',
      typeFullDesc: 'Error',
      typeGroupCode: 10,
    },
  ],
};

export const data = {
  view: true,
};

export const Token =
  'eyJraWQiOiIxbzF5bTc2ZGJxcWRja2hsZWkiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1aWQiOiIwMHV1Mm45OWkzSHE2YkJvQTBoNyIsInN1YiI6InNocmF2YW4ua2FycmFAY2FyZGluYWxoZWFsdGguY29tIiwiYXVkIjoiMG9hejc2YjljcTNnNXUxNzkwaDciLCJ1c2VyX2NsYWltcyI6IntcImZpcnN0TmFtZVwiOlwiU2hyYXZhblwiLFwibGFzdE5hbWVcIjpcIkthcnJhXCIsXCJlbWFpbElkXCI6XCJzaHJhdmFuLmthcnJhQGNhcmRpbmFsaGVhbHRoLmNvbVwiLFwidXNlcklkXCI6XCJzaHJhdmFuLmthcnJhQGNhcmRpbmFsaGVhbHRoLmNvbVwiLFwicm9sZU51bXNcIjpbMTAyXX0iLCJpc3MiOiJodHRwczpcL1wvYXBpLmNhcmRpbmFsaGVhbHRoLmNvbSIsImV4cCI6MTYyMjg5MjU0NSwiaWF0IjoxNjIyODg4OTQ1LCJ1c2VyaW5mbyI6IntcInN1YlwiOlwiMDB1dTJuOTlpM0hxNmJCb0EwaDdcIixcIm5hbWVcIjpcIlNocmF2YW4gS2FycmFcIixcImxvY2FsZVwiOlwiZW4tVVNcIixcImVtYWlsXCI6XCJzaHJhdmFuLmthcnJhQGNhcmRpbmFsaGVhbHRoLmNvbVwiLFwicHJlZmVycmVkX3VzZXJuYW1lXCI6XCJzaHJhdmFuLmthcnJhQGNhcmRpbmFsaGVhbHRoLmNvbVwiLFwiZ2l2ZW5fbmFtZVwiOlwiU2hyYXZhblwiLFwiZmFtaWx5X25hbWVcIjpcIkthcnJhXCIsXCJ6b25laW5mb1wiOlwiQW1lcmljYVwvTG9zX0FuZ2VsZXNcIixcInVwZGF0ZWRfYXRcIjoxNjIxMzU0MzQzLFwiZW1haWxfdmVyaWZpZWRcIjp0cnVlfSIsImp0aSI6IjMwNzY3MDExLTM2OTItNDAzNC1hNGRjLWY1NzBhNDUyMzFmNSIsImNpZCI6IjBvYXo3NmI5Y3EzZzV1MTc5MGg3In0.YO9Juyj7h0S3SLD31HCVxyKYnA0lUo2s6viljq0g16pPQ-myTvv08-Rc2cr2_J2mgLL2XX8d2iljKAKk1W258RfpC597E_p3rnTRpWQt73XmIXgvRjnDqbPWBz8NL_uvZcj7I76umJmFNaufGlF_qKAetpefUGCIE3Qy5nPMLsQG9c8rIWtS7yWdg1baYhGWwTblZXH1gE36DU4koSMuGH8X2kTPWbF5TAKrMhoql0X4oe7455DpId70ksFntBm-gezvyDcvw9YszYFPyGyfm-D7UcpOuBrbjdVJTh81dqqgE73SQ9ex3HccajKyqrJJb1u7PllOqWAVTPsQB6722A';

export const configData = {
  baseUrl: 'https://nphsden-api-dev.apps.cf.np.gc.cahcloud.net/',
  baseAdminUrl:
    'https://ndex-user-administration-api-dev.apps.cf.np.gc.cahcloud.net/',
  baseAuthUrl: 'https://nphsden-auth-api-dev.apps.cf.np.gc.cahcloud.net/',
  apiRequestTimeout: '300',
  clientId: '',
  issuer: 'https://myidb2bstg.cardinalhealth.com',
  apigeeTokenEndPoint:
    'https://api.stage.cardinalhealth.com/oauth2/v2/token/jwtforoktab2b',
  googleMapsAPIKey: 'test',
  exportLimit:'5000'
};
