<div mat-dialog-title>
  <h4 class="dialog-title" id="pageTitle">Verification</h4>
</div>
<form
  name="verification"
  [formGroup]="verificationForm"
  #formDirective="ngForm"
>
  <div mat-dialog-content>
    <div class="login-error" *ngIf="loginFailError || incorrectUser">
      <div class="exclamation-container">
        <div class="exclamation-circle">
          <em class="fa fa-exclamation"></em>
        </div>
      </div>
      <div class="error">
        {{loginFailError? 'Invalid credentials.': 'Please use logged in credentials.'}}
      </div>
    </div>
    <div class="row">
      <mat-form-field class="col-md-12">
        <mat-label>Username</mat-label>
        <input id="username" matInput required formControlName="username" />
        <mat-error>Required Field</mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-md-12">
        <mat-label>Password</mat-label>
        <input
          id="password"
          matInput
          type="password"
          required
          formControlName="password"
        />
        <mat-error>Required Field</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="action" mat-dialog-actions>
    <button
      id="noButton"
      type="button"
      mat-button
      class="button secondary"
      [mat-dialog-close]="false"
    >
      No
    </button>
    <button
      id="yesButton"
      type="submit"
      mat-button
      cdkFocusInitial
      class="button primary"
      (click)="verify()"
    >
      Yes
    </button>
  </div>
</form>
