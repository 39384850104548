import { NgModule } from '@angular/core';

import { SanitizePipe } from '../pipes/sanitize.pipe';
import { FilterPipe } from '../pipes/filter.pipe';

@NgModule({
  declarations: [SanitizePipe, FilterPipe],
  exports: [SanitizePipe, FilterPipe],
})
export class PipeModule {}
