import { MatDialogConfig } from '@angular/material/dialog';

/***************Enum Constants***************/

export enum DialogActions {
  YesNo = 1,
  YesNoCancel = 2,
  Ok = 3,
  OkCancel = 4,
}

export enum DialogAction {
  Yes = 'Yes',
  No = 'No',
  Save = 'SAVE',
  Cancel = 'CANCEL',
  Close = 'CLOSE',
  Ok = 'OK',
}

export enum DialogReasons {
  Yes = 'Yes',
  No = 'No',
  Cancel = 'CANCEL',
  Close = 'CLOSE',
}

export enum DialogType {
  Success = 'fa-check-circle',
  Error = 'fa-exclamation-circle',
  Warning = 'fa-exclamation-triangle',
  Information = 'fa-info-circle',
}

export enum ButtonTheme {
  Primary = 'primary',
  Secondary = 'secondary',
  Default = 'secondary',
}

/***************Enum Constants***************/

/***************Confirm Dialog Modal***************/

export class ConfirmDialog {
  title = 'Confirm';
  message: string;
  actions: DialogActions = DialogActions.YesNo;
  config?: MatDialogConfig;
  action = {
    yes: { text: DialogAction.Yes, theme: ButtonTheme.Primary },
    no: { text: DialogAction.No, theme: ButtonTheme.Secondary },
    cancel: { text: DialogAction.Cancel, theme: ButtonTheme.Default },
  };

  constructor() {}
}

/***************Confirm Dialog Modal***************/

/***************Information Dialog Modal***************/

export class InformationDialog {
  title = 'Information';
  message: string;
  actions: DialogActions = DialogActions.Ok;
  config?: MatDialogConfig;
  action = {
    ok: { text: DialogAction.Ok, theme: ButtonTheme.Primary },
    cancel: { text: DialogAction.Cancel, theme: ButtonTheme.Default },
  };

  constructor() {}
}

export class MessageDialog {
  title = 'Information';
  type: DialogType = DialogType.Information;
  message: string;
  actions: DialogActions = DialogActions.Ok;
  config?: MatDialogConfig;
  action = {
    ok: { text: DialogAction.Ok, theme: ButtonTheme.Primary },
    cancel: { text: DialogAction.Cancel, theme: ButtonTheme.Default },
  };

  constructor() {}
}

/***************Information Dialog Modal***************/
