export enum UserProperty {
  Detail = 'detail',
  RolePermissions = 'permissions',
  Accounts = 'accounts',
  UserPreferences = 'preferences',
  Roles = 'roles',
}

export enum UserType {
  Employee = 1,
  Customer = 2,
}
