import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'ndex-ui-date-tooltip',
  templateUrl: './date-tooltip.component.html',
  styleUrls: ['./date-tooltip.component.scss']
})
export class DateTooltipComponent {

  fieldName: string = '';
  left: number = 0;
  top: number = 0;
  position: string

  custTime: string = '';
  dispTime: string = '';
  delTime: string = '';
  pharmTime: string = '';
  localTime: string = '';
  
}