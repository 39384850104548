(window as any).URLSearchParams =
  window.URLSearchParams ||
  function(searchString): any {
    const self = this;
    self.searchString = searchString;
    self.get = (name) => {
      const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(
        self.searchString
      );
      if (results == null) {
        return null;
      } else {
        return decodeURI(results[1]) || 0;
      }
    };
  };
