import { CacheResolverService } from '../cache/cache-resolver.service';

import { StaticBase } from './static-base';

export class TypeGroup extends StaticBase {
  /*********************Properties*********************/
  private selectorPropertyName = 'typeGroupCode';
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private selectorName: string,
    private primaryKey: string,
    private storageCache: CacheResolverService
  ) {
    super(selectorName, primaryKey, storageCache);
  }
  /*********************Constructor*********************/

  /*********************Methods*********************/

  /**
   * Get typegroup object by group code
   */
  get(groupCode: number) {
    return this.getItem(this.selectorPropertyName, groupCode);
  }

  /*********************Methods*********************/
}
