<div class="wrapper">
  <div class="container-fluid p-0">
    <div class="d-flex flex-row">
      <div *ngIf="authenticated" class="d-flex flex-column nav-container">
        <ndex-navbar #navbar class="navigation flex-shrink-0"></ndex-navbar>
      </div>
      <div class="d-flex flex-column main-body" (click)="listenClick()">
        <ndex-header *ngIf="authenticated"></ndex-header>
        <div class="main-container">
          <router-outlet></router-outlet>
        </div>
        <ndex-footer></ndex-footer>
      </div>
    </div>
  </div>
</div>
