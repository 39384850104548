import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

import { PageConfig, PaginatorConfig } from '../../models/paginator';

@Component({
  selector: 'ndex-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnChanges {
  /*********************Properties*********************/

  pageNumbers: Array<void>;

  /*********************Properties*********************/

  /*********************Events*********************/

  @Input() paginatorConfig: PaginatorConfig;
  @Input() pageConfig: PageConfig;
  @Output() changeData = new EventEmitter<number>();
  @Output() pageChange = new EventEmitter<number>();

  /*********************Events*********************/

  /*********************Implementation Methods*********************/
  ngOnChanges(): void {
    this.pagifyData();
  }
  /*********************Implementation Methods*********************/

  /*********************Component Methods*********************/
  changePage(pageNum: number): void {
    this.paginatorConfig.currentPage = pageNum;
    this.pagifyData();
  }

  pagifyData(): void {
    this.paginatorConfig.totalPages = Math.ceil(
      this.pageConfig.totalElements / this.paginatorConfig.perPage
    );
    this.pageNumbers = new Array(this.paginatorConfig.totalPages);

    if (this.paginatorConfig.currentPage > this.paginatorConfig.totalPages) {
      this.paginatorConfig.currentPage = 1;
    }

    const reqStartIndex =
      this.paginatorConfig.currentPage === 1
        ? 0
        : (this.paginatorConfig.currentPage - 1) * this.paginatorConfig.perPage;
    const pageStartIndex = this.pageConfig.page * this.pageConfig.pageSize;
    const pageEndIndex =
      (this.pageConfig.page + 1) * this.pageConfig.pageSize - 1;

    if (reqStartIndex > pageEndIndex || reqStartIndex < pageStartIndex) {
      this.pageChange.emit(
        Math.floor(reqStartIndex / this.pageConfig.pageSize)
      );
    } else {
      const startIndex =
        reqStartIndex - this.pageConfig.page * this.pageConfig.pageSize;
      this.changeData.emit(startIndex);
    }
  }
  /*********************Component Methods*********************/
}
