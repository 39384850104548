export class CacheConstant {
  static readonly StaticData = {
    Name: 'static-data',
    AppPreferences: 'appPreferencesList',
    Messages: 'messageRefList',
    State: 'stateList',
    TypeGroups: 'typeGroupRefList',
    TypeRefs: 'typeRefList',
  };

  static readonly OktaAuth = 'okta-auth';
  static readonly UserAuth = 'user-auth';

  static readonly RedirectNavigation = 'redirect-navigation';

  static readonly UserPreferences = 'user-preferences';
}
