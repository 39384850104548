import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export enum SanitizeType {
  Html = 'html',
  Style = 'style',
  Script = 'script',
  Url = 'url',
  ResourceUrl = 'resourceUrl',
}

@Pipe({
  name: 'sanitize',
})
export class SanitizePipe implements PipeTransform {
  /*********************Constructor*********************/
  constructor(private sanitizer: DomSanitizer) {}
  /*********************Constructor*********************/

  /*********************Implementation Methods*********************/

  transform(value: any, type: string): any {
    switch (type) {
      case SanitizeType.Html:
        return this.sanitizer.bypassSecurityTrustHtml(value);

      case SanitizeType.Style:
        return this.sanitizer.bypassSecurityTrustStyle(value);

      case SanitizeType.Script:
        return this.sanitizer.bypassSecurityTrustScript(value);

      case SanitizeType.Url:
        return this.sanitizer.bypassSecurityTrustUrl(value);

      case SanitizeType.ResourceUrl:
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);

      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }

  /*********************Implementation Methods*********************/
}
