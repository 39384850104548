import { Menu } from './common';

import {
  OrderRoutes,
  LogisticsRoutes,
  AdminRoutes,
  ReportRoutes,
  SettingRoutes
} from '../core/constants/route.constant';

export const HomeMenu: Menu = {
  src: 'assets/images/home-menu.png',
  title: 'Home',
  name: 'home',
  url: 'home',
  config: { selected: false },
};

export const OrderMenu: Menu = {
  src: 'assets/images/order.png',
  title: 'Orders',
  name: 'order',
  url: '',
  config: { selected: false },
  children: [
    {
      title: 'Order Details',
      url: OrderRoutes.OrderDetail.Url,
    },
    {
      title: 'Provider Data',
      url: OrderRoutes.CustomerData.Url,
    },
    {
      title: 'Product Data',
      url: OrderRoutes.ProductData.Url,
    },
    {
      title: 'Location Data',
      url: OrderRoutes.LocationData.Url,
    },
  ],
};

export const LogisticMenu: Menu = {
  src: 'assets/images/logistics.png',
  title: 'Logistics',
  name: 'logistic',
  url: '',
  config: { selected: false },
  children: [
    {
      title: 'Logistics Dashboard',
      url: LogisticsRoutes.Dashboard.Url,
    },
    {
      title: 'Logistics Details',
      url: LogisticsRoutes.Logistics.Url,
    },
  ],
};

export const InventoryMenu: Menu = {
  src: 'assets/images/inventory.png',
  title: 'Inventory',
  name: 'inventory',
  url: 'inventory',
  config: { selected: false },
};

export const AdministrationModule: Menu = {
  src: 'assets/images/administration.png',
  title: 'Administration',
  name: 'administration',
  url: '',
  config: { selected: false },
  children: [
    {
      title: 'User Administration',
      url: AdminRoutes.UserAdmin.Url,
    },
    {
      title: 'Ship-To Administration',
      url: AdminRoutes.ShipToAdmin.Url,
    },
  ],
};

export const ReportMenu: Menu = {
  src: 'assets/images/reports.png',
  title: 'Reports',
  name: 'report',
  url: '',
  config: { selected: false },
  children: [
    {
      title: 'Request Reports',
      url: ReportRoutes.RequestReports.Url,
    },
    {
      title: 'Download Reports',
      url: ReportRoutes.DownloadReports.Url,
    },
    {
      title: 'Request Invoice',
      url: ReportRoutes.InvoiceReports.Url,
    },
  ],
};

export const SettingMenu: Menu = {
  src: 'assets/images/cog.png',
  title: 'Settings',
  name: 'setting',
  url: SettingRoutes.OrderEmailNotifications.Url,
  config: { selected: false },
};

export const ContactUsMenu: Menu = {
  src: 'assets/images/contact-us.png',
  title: 'Contact Us',
  name: 'contact',
  url: 'contact-us',
  config: { showChild: false, selected: false },
};

export const LogoutMenu: Menu = {
  src: 'fa-power-off',
  title: 'Logout',
  name: 'logout',
  url: '',
  config: { showChild: false, selected: false },
};
