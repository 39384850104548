import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';

import { EncDecAlgo } from 'src/app/core/constants/app.constant';

@Injectable({
  providedIn: 'root',
})
/**
 * For documentation, please visit https://cryptojs.gitbook.io/docs/.
 */
export class EncDecService {
  /*********************Properties*********************/

  private get defaultSecretKey(): string {
    return btoa(btoa('Qk9TU0VuY3J5cHREZWNyeXB0'));
  }

  private get encoder(): any {
    return CryptoJS.enc.Utf16;
  }

  private get cipherOption(): any {
    return {
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.AnsiX923,
    };
  }

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor() {}
  /*********************Constructor*********************/

  /*********************Service Methods*********************/

  protected encrypt(
    value: any,
    key: string = '',
    algo: EncDecAlgo = EncDecAlgo.AES,
    option: any = null
  ): string {
    const algorithm = this.getAlgorithm(algo);
    const cipherOption = Object.assign(this.cipherOption, option);
    const message = this.encoder.parse(value.toString());
    return algorithm
      .encrypt(message, this.getSecretKey(key), cipherOption)
      .toString();
  }

  protected decrypt(
    value: any,
    key: string = '',
    algo: EncDecAlgo = EncDecAlgo.AES,
    option: any = null
  ): string {
    const algorithm = this.getAlgorithm(algo);
    const cipherOption = Object.assign(this.cipherOption, option);
    const decrypted = algorithm.decrypt(
      value,
      this.getSecretKey(key),
      cipherOption
    );
    return decrypted.toString(this.encoder);
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private getAlgorithm(algo: EncDecAlgo) {
    let algorithm = null;

    switch (algo) {
      case EncDecAlgo.DES:
        algorithm = CryptoJS.DES;
        break;

      case EncDecAlgo.TripleDES:
        algorithm = CryptoJS.TripleDES;
        break;

      case EncDecAlgo.RC4:
        algorithm = CryptoJS.RC4;
        break;

      case EncDecAlgo.RC4Drop:
        algorithm = CryptoJS.RC4Drop;
        break;

      case EncDecAlgo.Rabbit:
        algorithm = CryptoJS.Rabbit;
        break;

      case EncDecAlgo.RabbitLegacy:
        algorithm = CryptoJS.RabbitLegacy;
        break;

      case EncDecAlgo.AES:
      default:
        algorithm = CryptoJS.AES;
        break;
    }

    return algorithm;
  }

  private getSecretKey(key: string): string {
    if (!key) {
      key = this.defaultSecretKey;
    }

    return key;
  }

  /*********************Private Methods*********************/
}
