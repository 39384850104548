import { Injectable } from '@angular/core';

import { CacheDuration } from '../constants/app.constant';
import { EncDecService } from 'src/app/shared/utils/enc-dec.service';

@Injectable({
  providedIn: 'root'
})
export class CacheResolverService extends EncDecService {
  /*********************Properties*********************/
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor( ) {
    super();
  }
  /*********************Constructor*********************/

  /*********************Service Methods*********************/
  /**
   * Gets the value from cache if the key is provided.
   */
  get(key: string): any {
    let value;

    try {
      let encryptValue = localStorage.getItem(key);
      let valueObj = JSON.parse(this.decrypt(encryptValue));
      if (new Date().getTime() < valueObj.expiry) {
        value = valueObj.value;
      }
    } catch (error) {}

    return value || undefined;
  }

  /**
   * Sets the value with key in the cache.
   */
  set(key: string, value: any, expiry?: number): void {
    expiry = expiry || CacheDuration.Default;
    expiry =
      expiry >= Number.MAX_VALUE
        ? Number.MAX_VALUE
        : Date.now() + expiry * 1000;

    const valueObj = {value, expiry};

    const encryptValue = this.encrypt(JSON.stringify(valueObj));
    localStorage.setItem(key, encryptValue);
  }

  /**
   * Checks if a key exists in cache
   */
  has(key: string): boolean {
    // Cache service 'has' operation doesn't check cache expiration time.
    // So to avoid issue in future, check actual value by reading from cache and
    // if value exist then return true otherwise false.
    const value = this.get(key);
    return value ? true : false;
  }

  /**
   * Deletes the value from cache if the key is provided.
   */
  remove(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * Clears all the key/value from cache.
   */
  clear(): void {
    localStorage.clear();
  }

  /*********************Service Methods*********************/
}
