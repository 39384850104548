import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import { AppCacheModule } from './cache/cache.module';
import { DialogModule } from './dialog/dialog.module';
import { ErrorModule } from './error/error.module';
import { ServicesModule } from './services/services.module';

import { EnvironmentFactory } from './environment/environment.factory';
import { EnvironmentService } from './environment/environment.service';

import './extensions/array.extensions';
import './extensions/number.extensions';
import './extensions/object.extensions';
import './extensions/string.extensions';
import './extensions/window.extensions';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AppCacheModule,
    ServicesModule,
    DialogModule,
    ErrorModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: EnvironmentFactory,
      deps: [EnvironmentService],
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
