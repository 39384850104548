import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { of, Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { ToastService } from 'src/app/shared/utils/toast.service';
import { ErrorService } from '../error/error.service';

import {
  AppConstant,
  SecurityErrorCodes,
  SignOutReason,
} from '../constants/app.constant';
import { ClientError } from '../error/model/client-error';
import { ServerError } from '../error/model/server-error';
import { RouteUtilService } from 'src/app/shared/utils/route-util.service';
import { LogisticsRoutes, MasterRoutes } from '../constants/route.constant';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /***************Properties***************/
  private handledSessionExpiration = false;
  /***************Properties***************/

  /*********************Constructor*********************/
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private toast: ToastService,
    private routeUtils: RouteUtilService
  ) {
    this.authService.authenticated$.subscribe((response) => {
      this.handledSessionExpiration = false;
    });
  }
  /*********************Constructor*********************/

  /*********************Implementation Methods*********************/

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {}),
      catchError((error) => {
        if (this.handledSessionExpiration) {
          return of(error);
        }

        if (error instanceof HttpErrorResponse) {
          let err = null;

          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred.
            err = new ClientError(error.error.message);
          } else {
            // A server-side error occurred.
            err = new ServerError(error.error, error.status);
            err.url = error.url;

            if (this.isAccessRestricted(err)) {
              this.routeUtils.navigate(MasterRoutes.NoAccess);
              return;
            }

            if (this.isSessionExpired(err)) {
              this.toast.error(AppConstant.Messages.SignOut[err.messageId], null, { enableHtml: true });
              err.handled = true;
              this.handledSessionExpiration = true;

              setTimeout(() => {
                this.authService.signOut(SignOutReason.SessionExpired);
              }, (2000));
            }

            err = this.errorService.resolveServerError(err);

            if (this.errorService.handleServerException(err)) {
              this.toast.error(err.message, null, { enableHtml: true });
              err.handled = true;
            }
          }

          err.status = error.status;
          err.statusText = error.statusText;
          return throwError(err);
        }

        return throwError(error);
      })
    );
  }

  /*********************Implementation Methods*********************/

  /******************Private Methods******************/

  private isSessionExpired(error: ServerError): boolean {
    const sessionExpired =
      ([
        SecurityErrorCodes.InvalidToken as string,
        SecurityErrorCodes.NoShipToAssigned as string,
        SecurityErrorCodes.SessionExpired as string,
      ].includes(error.messageId) ||
        error.logoutUser) &&
      !this.handledSessionExpiration;

    if (this.handledSessionExpiration) {
      error.handled = true;
    }

    return sessionExpired;
  }

  private isAccessRestricted(error: ServerError): boolean {
    return error.messageId === SecurityErrorCodes.ServiceAccess;
  }

  /*********************Private Methods*********************/
}
