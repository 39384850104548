<header id="header">
  <img
    id="CardinalLogoImage"
    class="logo-img"
    src="assets/images/cardinal-logo.png"
    alt="Cardinal Logo"
    title="Home"
  />
  <img
    id="NdexLogoImage"
    class="ndex-logo"
    src="assets/images/ndex-logo.png"
    alt="Ndex Logo"
    title="NDEX"
  />
  <div id="userName" class="username">Welcome {{getUser()}}</div>
</header>
