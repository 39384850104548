import {
  AfterViewInit,
  Component,
  HostListener,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';

import { NavbarComponent } from './common/navbar/navbar.component';
import { MasterRoutes, RouteNames } from './core/constants/route.constant';
import { AuthService } from './core/services/auth.service';
import { OktaPkceAuthService } from './core/services/okta-pkce-auth.service';
import { UserService } from './core/services/user.service';

@Component({
  selector: 'ndex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  /*********************Properties*********************/
  private subscription = { authenticated: null };
  title = 'ndex-management-portal';
  authenticated: boolean;
  private endTime = 15*60;
  timerSubscription: Subscription;

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  resetTimer() {
    this.resetActivityTimer();
  }
  /*********************Properties*********************/

  @ViewChild('navbar') navBar: NavbarComponent;

  /*********************Implementation Methods*********************/
  ngAfterViewInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.subscription.authenticated.unsubscribe();
  }
  /*********************Implementation Methods*********************/

  constructor(
    private oktapkceAuth: OktaPkceAuthService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {}

  /*********************Component Methods*********************/

  listenClick(): void {
    this.navBar.closeMenu();
  }

  /*********************Component Methods*********************/
  /*********************Private Methods*********************/

  private init(): void {
    this.subscription.authenticated = this.authService.authenticated$.subscribe(
      (response) => this.onUserProfileLoaded()
    );

    this.authService.isAuthenticated().then((authResponse) => {
      if (window.location.search.substring(1).startsWith('iss=')) {
        this.validateInternalAuthentication(authResponse);
      }

      if (authResponse.authenticated.app) {
        this.onUserProfileLoaded();
      } else if (
        window.location.pathname === '/' &&
        !this.oktapkceAuth.isAuthCallback()
      ) {
        this.oktapkceAuth.signOut();
      }
    });
  }

  private onUserProfileLoaded() {
    const userDetails = this.userService.getDetails();

    if (userDetails) {
      this.authenticated = true;
      this.startActivityTimer();

      if (this.oktapkceAuth.isAuthCallback()) {
        this.router.navigateByUrl(
          MasterRoutes.Order + '/' + RouteNames.OrderDetail
        );
      }
    } else {
      this.oktapkceAuth.signOut();
    }
  }

  private validateInternalAuthentication(authResponse) {
    !authResponse.authenticated.app
      ? this.router.navigateByUrl(MasterRoutes.Callback)
      : this.onUserProfileLoaded();
  }

  resetActivityTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.startActivityTimer();
  }

  startActivityTimer(endTime: number = this.endTime) {
    const interval = 1000;
    const duration = endTime;
    this.timerSubscription = timer(0, interval)
      .pipe(take(duration))
      .subscribe(
        value => {},
        (err) => {},
        () => {
          if (this.authenticated) {
            this.oktapkceAuth.signOut();
          }
        }
      );
  }

  /*********************Private Methods*********************/
}
