import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[ndexClickOutside]',
})
export class ClickOutsideDirective {
  /*********************Properties*********************/
  @Output() outsideClick = new EventEmitter<MouseEvent>();
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(private elementRef: ElementRef) {}
  /*********************Constructor*********************/

  /*********************Events*********************/

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.outsideClick.emit(event);
    }
  }

  /*********************Events*********************/
}
