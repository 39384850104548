import { AfterViewInit, Component } from '@angular/core';

import { OktaPkceAuthService } from 'src/app/core/services/okta-pkce-auth.service';
import { AppService } from 'src/app/app.service';

import { RouteUtilService } from 'src/app/shared/utils/route-util.service';

import { OktaConstant } from 'src/app/core/constants/okta.constant';

import { IResponse } from 'src/app/shared/models/app';
import { AuthService } from 'src/app/core/services/auth.service';
import { TokenService } from 'src/app/core/services/token.service';

@Component({
  selector: 'ndex-callback',
  templateUrl: './callback.component.html'
})
export class CallbackComponent implements AfterViewInit {
  /*********************Properties*********************/
  readonly config = {
    message: OktaConstant.Messages.SigninIntoApp,
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private appService: AppService,
    private oktaPkceAuth: OktaPkceAuthService,
    private authService: AuthService,
    private token: TokenService,
    private routeUtils: RouteUtilService
  ) {}
  /*********************Constructor*********************/

  /*********************Implementation Methods*********************/
  ngAfterViewInit(): void {
    !this.getSearch() ? this.init() : this.validateAuthCode();
  }
  /*********************Implementation Methods*********************/

  /*********************Private Methods*********************/

  private init(): void {
    this.config.message = OktaConstant.Messages.VerifyAuthentication;

    this.authService.isAuthenticated().then((response: any) => {
      if (response.authenticated.okta) {
        this.oktaPkceAuth
          .getToken()
          .then((token: any) => {
            token.accessToken ? this.validateToken(token) : this.initAuthCode();
          })
          .catch(() => {
            this.initAuthCode();
          });
      } else {
        this.initAuthCode();
      }
    });
  }

  private initAuthCode(): void {
    this.oktaPkceAuth.initAuthCode().catch((error) => {
      this.oktaPkceAuth.auth.validate = false;
      this.routeUtils.navigate('login');
    });
  }

  private validateAuthCode(): void {
    this.config.message = OktaConstant.Messages.SigninIntoApp;
    const urlParams = this.toKeyValuePair(window.location.search.substring(1));

    if (!urlParams.error) {
      this.oktaPkceAuth.redirect().then((response) => {
        response.hasError ? this.signOut() : this.validateToken(response);
      });
    } else {
      this.signOut();
    }
  }

  private signOut(error: string = null): void {
    this.oktaPkceAuth.auth.validate = false;
    this.oktaPkceAuth.signOut();
  }

  private validateToken(response: any): void {
    this.token
      .validate(response.accessToken, response.idToken)
      .subscribe((tokenResponse: IResponse) => {
        if (!tokenResponse.hasError) {
          this.appService.load();
        } else {
          this.signOut();
        }
      });
  }

  private toKeyValuePair(urlParamaters: string): any {
    const array = urlParamaters.split('&');
    const data = {};

    for (const obj of array) {
      const value = obj.split('=');
      data[value[0]] = value[1];
    }

    return data;
  }

  getSearch(): string {
    return window.location.search;
  }

  /*********************Private Methods*********************/
}
