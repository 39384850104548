import { NgModule } from '@angular/core';

import { HttpService } from './http.service';
import { BaseService } from './base.service';
import { AuthService } from './auth.service';
import { StaticService } from '../static/static.service';
import { EnvironmentService } from '../environment/environment.service';
import { TokenService } from './token.service';
import { UserService } from './user.service';

@NgModule({
  providers: [
    HttpService,
    EnvironmentService,
    BaseService,
    AuthService,
    TokenService,
    UserService,
    StaticService,
  ],
})
export class ServicesModule {}
