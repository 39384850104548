import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogActions, MessageDialog, DialogType } from '../dialog.model';

@Component({
  selector: 'ndex-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
  /*********************Properties*********************/
  readonly config = { class: '' };
  readonly actions = DialogActions;
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { message: MessageDialog }
  ) {}
  /*********************Constructor*********************/

  /*********************Implementation Methods*********************/
  ngOnInit(): void {
    this.init();
  }
  /*********************Implementation Methods*********************/

  /*********************Component Methods*********************/

  onOkClick(): void {
    this.dialogRef.close(true);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  /*********************Component Methods*********************/

  /*********************Private Methods*********************/

  private init(): void {
    switch (this.data.message.type) {
      case DialogType.Success:
        this.config.class = 'success';
        break;

      case DialogType.Error:
        this.config.class = 'error';
        break;

      case DialogType.Warning:
        this.config.class = 'warning';
        break;

      case DialogType.Information:
      default:
        this.config.class = 'information';
        break;
    }
  }

  /*********************Private Methods*********************/
}
