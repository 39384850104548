import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Subject } from 'rxjs';

import { ConfirmDialog, DialogActions, DialogReasons } from '../dialog.model';

@Component({
  selector: 'ndex-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
  /*********************Properties*********************/
  readonly actions = DialogActions;

  confirm: ConfirmDialog;
  onClose: Subject<string> = new Subject<string>();
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}
  /*********************Constructor*********************/

  /*********************Implementation Methods*********************/
  /*********************Implementation Methods*********************/

  /*********************Component Methods*********************/

  onCloseClick(): void {
    this.closeDialog(DialogReasons.No, false);
  }

  onYesClick(): void {
    this.closeDialog(DialogReasons.Yes, true);
  }

  onNoClick(): void {
    this.closeDialog(DialogReasons.No, false);
  }

  onCancelClick(): void {
    this.closeDialog(DialogReasons.Cancel, false);
  }

  /*********************Component Methods*********************/

  /*********************Private Methods*********************/

  private closeDialog(reason: DialogReasons, result: boolean): void {
    this.onClose.next(reason);
    this.dialogRef.close(result);
  }

  /*********************Private Methods*********************/
}
