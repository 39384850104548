import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { HttpHeader } from '../constants/app.constant';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  /*********************Properties*********************/
  protected get options(): any {
    const headers = {
      Accept: 'application/json',
    };

    headers[HttpHeader.ContentType] = 'application/json';

    return {
      headers,
    };
  }

  private readonly httpMethod = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(protected http: HttpClient) {}
  /*********************Constructor*********************/

  /*********************Service Methods*********************/

  protected fetch<T>(url: string, options?: any | {}): Observable<T> {
    return this.invokeService<T>(this.httpMethod.GET, url, null, options);
  }

  protected post<T>(
    url: string,
    body: object,
    options?: any | {}
  ): Observable<T> {
    return this.invokeService<T>(this.httpMethod.POST, url, body, options);
  }

  protected put<T>(
    url: string,
    body: object,
    options?: any | {}
  ): Observable<T> {
    return this.invokeService<T>(this.httpMethod.PUT, url, body, options);
  }

  protected delete<T>(
    url: string,
    body?: object,
    options?: any | {}
  ): Observable<T> {
    return this.invokeService<T>(this.httpMethod.DELETE, url, body, options);
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private invokeService<T>(
    method: string,
    url: string,
    body?: object | null,
    options?: any | {}
  ): Observable<T> {
    let reqOptions = Object.clone(this.options);
    reqOptions = Object.assign(reqOptions, options);

    if (body) {
      reqOptions.body = body;
    }

    return this.http.request<T>(method, url, reqOptions).pipe(
      map((response) => this.onHttpResponse(response)),
      catchError(this.onHttpError())
    );
  }

  private onHttpResponse(response: any): any {
    const res: any = {
      data: response,
      error: null,
      hasError: false,
    };

    return res;
  }

  private onHttpError(): any {
    return (error: any): Observable<any> => {
      const res: any = {
        data: null,
        error,
        hasError: true,
      };

      return of(res);
    };
  }

  /*********************Private Methods*********************/
}
