<div class="page-container">
  <div class="search-box">
    <div id="title" class="search-box-header"></div>
    <div class="search-control-panel">
      <div class="no-access-wrapper">
        <h1>Access Denied</h1>
        <div class="access-error">
          You are not authorized to access the requested resource.
        </div>
      </div>
    </div>
  </div>
</div>
