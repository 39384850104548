import { Injectable } from '@angular/core';
import {
  MatDialogConfig,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';

import { ConfirmDialog, MessageDialog } from './dialog.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  /*********************Properties*********************/
  private readonly dialogConfig: MatDialogConfig = {
    autoFocus: true,
    disableClose: true,
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(private dialog: MatDialog) {}
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  confirm(
    confirm: ConfirmDialog,
    config?: MatDialogConfig
  ): MatDialogRef<ConfirmDialogComponent> {
    const dialogConfig = this.getDialogOptions(config, this.getConfirmConfig());

    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.openModal(
      ConfirmDialogComponent,
      dialogConfig
    );

    dialogRef.componentInstance.confirm = confirm;

    return dialogRef;
  }

  message(
    message: MessageDialog,
    config?: MatDialogConfig
  ): MatDialogRef<MessageDialogComponent> {
    const dialogConfig = this.getDialogOptions(config, this.getInfoConfig());
    dialogConfig.data = { message };

    const dialogRef: MatDialogRef<MessageDialogComponent> = this.openModal(
      MessageDialogComponent,
      dialogConfig
    );

    return dialogRef;
  }

  open(
    component: any,
    config: MatDialogConfig = this.dialogConfig
  ): {
    dialogRef: MatDialogRef<any>;
    result: Observable<any>;
  } {
    const dialogRef = this.openModal(component, config);

    return {
      dialogRef,
      result: new Observable<string>(this.getDialogSubscriber(dialogRef)),
    };
  }

  closeAll(): void {
    this.dialog.closeAll();
  }

  /*********************Utility Methods*********************/

  /*********************Private Methods*********************/

  private openModal(
    component: any,
    options?: MatDialogConfig
  ): MatDialogRef<any> {
    const modalOptions = this.getDialogOptions(options);
    return this.dialog.open(component, modalOptions);
  }

  private getDialogSubscriber(modelRef: MatDialogRef<any>): any {
    return (observer) => {
      const subscription = modelRef
        .afterClosed()
        .subscribe((reason: string) => {
          observer.next(reason);
          observer.complete();
        });

      return {
        unsubscribe(): void {
          subscription.unsubscribe();
        },
      };
    };
  }

  private getConfirmConfig(): MatDialogConfig {
    const config = this.getDefaultConfig();
    config.panelClass = 'confirm-dialog';

    return config;
  }

  private getInfoConfig(): MatDialogConfig {
    const config = this.getDefaultConfig();
    config.panelClass = 'info-dialog';

    return config;
  }

  private getDefaultConfig(): MatDialogConfig {
    const config = Object.clone(this.dialogConfig) as MatDialogConfig;
    config.autoFocus = false;

    return config;
  }

  private getDialogOptions(
    config: MatDialogConfig,
    defaultConfig: MatDialogConfig = this.dialogConfig
  ): MatDialogConfig {
    return Object.assign(defaultConfig, config);
  }

  /*********************Private Methods*********************/
}
