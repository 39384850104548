/***************Enum Constants***************/

export enum AppEnv {
  Local = 'local',
  Production = 'production',
}

export enum HttpHeader {
  ContentType = 'Content-Type',
  ApiKey = 'x-api-key',
  Token = 'access-token',
  Authorization = 'Authorization',
}

export enum HttpStatusCode {
  Timeout = 0,
  UnAuthorized = 401,
  BadRequest = 400,
  NotFound = 404,
  PreconditionRequired = 412,
}

export enum SecurityErrorCodes {
  ServiceAccess = "SEC05",
  NoShipToAssigned = "SEC07",
  SessionExpired = "SEC04",
  InvalidToken = "SEC01"
}

export enum ErrorNotificationType {
  Toast = 'TOASTER',
  Page = 'PAGE',
  Modal = 'MODAL',
}

export enum ErrorConfig {
  SysErrId = 'SYS100',
  BadRequestId = 'SYS400',
  NotFoundId = 'SYS404',
  PreconditionRequiredId = 'SYS412',
  TimeoutId = 'UI408',
  RespTooLarge = 'ORD06'
}

export enum CacheDuration {
  Default = 60 * 60 * 2,
  OneMinute = 60 * 1,
  OneHour = 60 * 60 * 1,
  TwoHour = 60 * 60 * 2,
  OneDay = 24 * 60 * 60 * 1,
}

export enum Configuration {
  API_KEY = 'apiKey',
  UNDER_MAITENANCE = 'appDown',
  INTERNAL_URL = 'bossInternalUrl',
  APP_TIMEOUT = 'appIdleTimeout',
  API_TIMEOUT = 'apiTimeout',
  OKTA_ISSUER = 'oktaIssuer',
  OKTA_CLIENT_ID = 'oktaClient',
  OKTA_REDIRECT_URL = 'oktaRedirect',
  OKTA_LOGOUT_URL = 'oktaLogout',
  PRODUCTION_FLAG = 'production',
  APIGEE_ENDPOINT = 'apigeeTokenEndpoint',
  CONFIG_API_ENDPOINT = 'configUrl',
  CONTENT_API_ENDPOINT = 'contentUrl',
  CONTRACT_API_ENDPOINT = 'accountContractUrl',
  PRODUCT_API_ENDPOINT = 'productUrl',
  ACCOUNT_MGMT_API_ENDPOINT = 'accountMgmtUrl',
  USER_API_ENDPOINT = 'userUrl',
  VENDOR_CONTRACT_API_ENDPOINT = 'vendorContractUrl',
  ACCOUNT_PAYABLE_API_ENDPOINT = 'accountPayableUrl',
  ACCOUNT_RCV_API_ENDPOINT = 'accountRcvUrl',
  FUSION_API_ENDPOINT = 'fusionUrl',
  APIGEE_TOKEN_ENDPOINT = 'apigeeTokenEndpoint',
  FEE_INDEXING_JOB_API_ENDPOINT = 'bossFeeIndexJobUrl',
  REPORT_API_ENDPOINT = 'reportUrl',
  AUTO_CONVERSION_API_ENDPOINT = 'autoConversionJobUrl',
}

export enum LogLevel {
  TRACE = 'trace',
  DEBUG = 'debug',
  INFO = 'info',
  LOG = 'log',
  WARN = 'warn',
  ERROR = 'error',
}

export enum EncDecAlgo {
  AES = 'AES',
  DES = 'DES',
  TripleDES = 'TripleDES',
  RC4 = 'RC4',
  RC4Drop = 'RC4Drop',
  Rabbit = 'Rabbit',
  RabbitLegacy = 'RabbitLegacy',
  EvpKDF = 'EvpKDF',
}

export enum SignOutReason {
  Default = 1,
  Timeout = 2,
  SessionExpired = 3,
}

export enum SortDirections {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum DateFormat {
  Default = 'MM/DD/YYYY',
  MMDDYYYY = 'MM/DD/YYYY',
  YYYYMMDD = 'YYYY-MM-DD',
  MMDDYYYYHMMA = 'MM/DD/YY H:MM A',
}

export enum Tier {
  one = 1,
  two = 2,
  three = 3
}

export const OrderStatuses = [
  { code: '10', value: 'Unverified' },
  { code: '20', value: 'Verified' },
  { code: '30', value: 'Forwarded Out' },
  { code: '40', value: 'Filled' },
  { code: '50', value: 'Packaged' },
  { code: '60', value: 'Dispatched' },
  { code: '70', value: 'Delivered' },
  { code: '80', value: 'Invoiced' },
  { code: '90', value: 'Cancelled' },
  { code: '100', value: 'End Of Day' },
];

export enum RedirectSearchTypes {
  SearchType = 'searchType',
  ShipTo = 'shipTo',
  Status = 'status',
  Order = 'order',
  Location = 'location',
  Material = 'material',
  Container = 0,
  CalibrationDate = 'calibration'
}

export const RedirectConstants =  {
  orderDetail: {
    types: {
      shipTo: 'selectedSearchField',
      location: 'selectedSearchField',
      order: 'orderNum',
      status: 'orderStatus',
      material: 'selectedSearchField',
      searchType: 'searchBy'
    }
  },
  inventory: {
    types: {
      material: 'products',
      calibration: 'calibFromDate',
      order: 'orderNum'
    }
  },
  logistics : {
    types: {
      container: 'cntnrNum'
    }
  }
}

export const BarChartColors = [
  { backgroundColor: '#f5c686' },
  { backgroundColor: '#cbc7c0' },
  { backgroundColor: '#64c8ce' },
  { backgroundColor: '#fbd71a' },
  { backgroundColor: '#cf70ad' },
]

export enum TooltipPosition {
  ABOVE = 'above',
  BELOW = 'below',
  LEFT = 'left',
  RIGHT = 'right',
  DYNAMIC = 'dynamic',
  DEFAULT = 'below'
}

export const HideTempProds = {
  materialId: ['108876'],
  prodConfigNum: ['15129']
}

export enum TimezoneMap {
  'GMT-11'=	'Pacific/Midway',
  'GST-10'= 'US/Hawaii',
  'GMT-7'=	'US/Arizona',
  'GMT-4'=	'America/Puerto_Rico',
  'GMT-3:30'=	'Canada/Newfoundland',
  'GMT-3'=	'America/Argentina/Cordoba',
  'GMT-1'=	'Atlantic/Cape_Verde',
  'GMT+1'=	'Pacific/Bougainville',
  'GMT+2'=	'Netherlands',
  'GMT+3'=	'Africa/Nairobi',
  'GMT+3:30'=	'Iran',
  'GMT+4'=	'Asia/Baku',
  'GMT+5'=	'Asia/Ashgabat',
  'GMT+5:30'=	'Asia/Kolkata',
  'GMT+6'=	'Asia/Dhaka',
  'GMT+7'=	'Asia/Bangkok',
  'GMT+9'=	'Japan',
  'GMT+9:30'=	'Australia/Adelaide',
  'GMT+10'=	'Australia/Sydney',
  'GMT+12'=	'Antarctica/McMurdo',
  'AST'= 'Canada/Atlantic',
  'AKST'=	'US/Alaska',
  'CST'=	'US/Central',
  'EST'=	'US/Eastern',
  'IST'= 'Asia/Kolkata',
  'MST'=	'US/Mountain',
  'PST'=  'US/Pacific',
  'WST'=	'Australia/West',
  'HST'=	'US/Hawaii',
}

/***************Enum Constants***************/

export class AppConstant {
  static readonly Title = {
    Ndex: 'NDEX',
  };
  static readonly Messages = {
    GenericErrorMessage:
      'An error has occurred  while processing your request. Please try again. If problem persist, contact customer service.',
    UnderMaintenance:
      'Site is temporarily unavailable, we are undergoing routine maintenance at this time.',
    NoDataFound:
      'No data found matching your search.',
    Confirm: {
      Save: {
        Title: 'Confirm Save',
        Message: 'Are you sure you want to save?',
      },
      Cancel: {
        Title: 'Confirm Cancel',
        Message:
          'Are you sure you want to cancel? Any work that is done so far will be discarded.',
      },
      Remove: {
        Title: 'Confirm Remove',
        Message:
          'This action is permanent and cannot be undone. Are you sure you want to remove?',
      },
      Delete: {
        Title: 'Confirm Delete',
        Message:
          'This action is permanent and cannot be undone. Are you sure you want to delete?',
      },
      AdminExit: {
        Title: 'Confirm Exit?',
        Message:
          'You have unsaved changes! If you leave, your changes will be lost. Confirm exit?',
      },
      ReportExit: {
        Title: 'Save Report',
        Message:
          'The new custom report or updated custom report will not be saved if you navigate away from the request reports screen. Do you want to save report?',
      },
      WidgetExit: {
        Title: 'Save Widget Selection',
        Message:
          'Your widget selection will be lost if you navigate away from the dashboard screen. Do you want to save widget selection?',
      }
    },
    Dialog: {
      Success: { Title: 'Success', Message: '' },
      Warning: { Title: 'Warning', Message: '' },
      Error: { Title: 'Error', Message: '' },
      Information: { Title: 'Information', Message: '' },
    },
    SignOut: {
      "SEC07": "No accounts assigned, please have administrator assign accounts and try again",
      "SEC04": "Session Expired. Please re-login",
      "SEC01": "Invalid Token. Please re-login"
    }
  };
}
