<div mat-dialog-title>
  <h4 class="dialog-title">{{ confirm?.title }}</h4>
</div>
<mat-dialog-content
  [innerHTML]="confirm?.message | sanitize: 'html'"
></mat-dialog-content>
<mat-dialog-actions class="text-right">
  <button
    type="button"
    class="button {{ confirm?.action.no.theme }}"
    (click)="onNoClick()"
  >
    {{ confirm?.action.no.text }}
  </button>
  <button
    type="button"
    class="button {{ confirm?.action.yes.theme }}"
    (click)="onYesClick()"
  >
    {{ confirm?.action.yes.text }}
  </button>
  <button
    type="button"
    class="button {{ confirm?.action.cancel.theme }}"
    (click)="onCancelClick()"
    *ngIf="confirm?.actions === actions.YesNoCancel"
  >
    {{ confirm?.action.cancel.text }}
  </button>
</mat-dialog-actions>
