import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';

import { DialogService } from 'src/app/core/dialog/dialog.service';
import { UserService } from 'src/app/core/services/user.service';

import {
  ButtonTheme,
  ConfirmDialog,
  DialogType,
  MessageDialog,
} from 'src/app/core/dialog/dialog.model';
import { MatDialogConfig } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { AppConstant } from 'src/app/core/constants/app.constant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  /*********************Properties*********************/
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private dialog: DialogService
  ) {}
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  loadScript(url: string): void {
    const script = document.createElement('script');
    const head = document.head;

    script.async = true;
    script.src = url;
    head.insertBefore(script, head.firstChild);
  }

  getDefaultLogMessage(): string {
    const userDetails = this.userService.getDetails();
    const deviceInfo = this.getUserAgentType();
    let logMessage = '[App: NDEX] [Device: ' + deviceInfo;

    if (userDetails && userDetails.loginId) {
      logMessage += ' [Login Id: ' + userDetails.loginId + ']';
    }

    return logMessage + '] ';
  }

  getDialogModal(
    title: string,
    message: string,
    yesTheme: ButtonTheme = ButtonTheme.Secondary
  ): ConfirmDialog {
    const modal = new ConfirmDialog();
    modal.title = title;
    modal.message = message;
    modal.action.yes.theme = yesTheme;
    modal.action.no.theme =
      yesTheme === ButtonTheme.Secondary
        ? ButtonTheme.Primary
        : ButtonTheme.Secondary;

    return modal;
  }

  confirm(modal: ConfirmDialog, config?: MatDialogConfig): Observable<boolean> {
    return this.dialog
      .confirm(modal, config)
      .afterClosed()
      .pipe(map((res) => (res ? true : false)));
  }

  confirmCancel(
    message: string = AppConstant.Messages.Confirm.Cancel.Message,
    title: string = AppConstant.Messages.Confirm.Cancel.Title
  ): Observable<boolean> {
    const modal = this.getDialogModal(title, message);

    return this.confirm(modal, {
      panelClass: ['confirm-dialog', 'confirm-cancel'],
    });
  }

  confirmRemove(
    message: string = AppConstant.Messages.Confirm.Remove.Message,
    title: string = AppConstant.Messages.Confirm.Remove.Title
  ): Observable<boolean> {
    const modal = this.getDialogModal(title, message);

    return this.confirm(modal, {
      panelClass: ['confirm-dialog', 'confirm-cancel'],
    });
  }

  successDialog(
    message: string = AppConstant.Messages.Dialog.Warning.Message,
    title: string = AppConstant.Messages.Dialog.Warning.Title,
    className: string = ''
  ): Observable<boolean> {
    return this.openMessageDialog(title, message, DialogType.Success, {
      panelClass: ['message-dialog', className],
    });
  }

  errorDialog(
    message: string = AppConstant.Messages.Dialog.Warning.Message,
    title: string = AppConstant.Messages.Dialog.Warning.Title,
    className: string = ''
  ): Observable<boolean> {
    return this.openMessageDialog(title, message, DialogType.Error, {
      panelClass: ['message-dialog', className],
    });
  }

  warningDialog(
    message: string = AppConstant.Messages.Dialog.Warning.Message,
    title: string = AppConstant.Messages.Dialog.Warning.Title,
    className: string = ''
  ): Observable<boolean> {
    return this.openMessageDialog(title, message, DialogType.Warning, {
      panelClass: ['message-dialog', className],
    });
  }

  infoDialog(
    message: string = AppConstant.Messages.Dialog.Warning.Message,
    title: string = AppConstant.Messages.Dialog.Warning.Title,
    className: string = ''
  ): Observable<boolean> {
    return this.openMessageDialog(title, message, DialogType.Information, {
      panelClass: ['message-dialog', className],
    });
  }

  unsavedChangesDialog(prompt: any) {
    const modal = new ConfirmDialog();
    modal.title = prompt.Title;
    modal.message = prompt.Message;
    return this.confirm(modal);
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  /*********************Utility Methods*********************/

  /*********************Private Methods*********************/

  private openMessageDialog(
    title: string,
    message: string,
    type: DialogType = DialogType.Information,
    config?: MatDialogConfig,
    okTheme: ButtonTheme = ButtonTheme.Primary
  ): Observable<boolean> {
    const dialog = new MessageDialog();
    dialog.title = title;
    dialog.message = message;
    dialog.type = type;
    dialog.action.ok.theme = okTheme;
    dialog.action.cancel.theme =
      okTheme === ButtonTheme.Secondary
        ? ButtonTheme.Primary
        : ButtonTheme.Secondary;

    return this.dialog
      .message(dialog, config)
      .afterClosed()
      .pipe(map((res) => (res ? true : false)));
  }

  private getUserAgentType(): string {
    return (
      this.getDeviceName() +
      ', ' +
      this.deviceService.os +
      ', ' +
      this.getBrowserInfo()
    );
  }

  private getDeviceName(): string {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    let name = isMobile || isTablet ? this.deviceService.device : 'Desktop';

    if (isMobile || isTablet) {
      name = isTablet ? 'Tablet(' : 'Mobile(';
      name += this.deviceService.device + ')';
    } else {
      name = 'Desktop';
    }

    return name;
  }

  private getBrowserInfo(): string {
    return (
      this.deviceService.browser + ' ' + this.deviceService.browser_version
    );
  }

  /*********************Private Methods*********************/
}
