import { Component } from '@angular/core';

import { AppConstant } from '../../constants/app.constant';

@Component({
  selector: 'ndex-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  /*********************Properties*********************/
  readonly config = {
    message: AppConstant.Messages.GenericErrorMessage,
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor() {}
  /*********************Constructor*********************/
}
