import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'ndex-ui-user-verification-modal',
  templateUrl: './user-verification-modal.component.html',
  styleUrls: ['./user-verification-modal.component.scss'],
})
export class UserVerificationModalComponent implements OnInit {
  verificationForm: UntypedFormGroup;
  loginFailError: boolean;
  incorrectUser: boolean;

  constructor(
    public dialogRef: MatDialogRef<UserVerificationModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.verificationForm = this.formBuilder.group({
      username: [''],
      password: [''],
    });
  }

  verify() {
    this.loginFailError = false;
    this.incorrectUser = false;

    if (this.verificationForm.valid) {
      let username = this.verificationForm.controls.username.value;
      let password = this.verificationForm.controls.password.value;

      if (this.userService.getDetails().emailId != username) {
        this.incorrectUser = true;
        return;
      }

      this.authService
        .authenticate({
          username: username,
          password: password,
          options: {
            multiOptionalFactorEnroll: false,
            warnBeforePasswordExpired: false,
          },
        })
        .subscribe((response: any) => {
          if (!response.hasError) {
            this.dialogRef.close(this.verificationForm.controls.username.value);
          } else {
            this.loginFailError = true;
          }
        });
    }
  }
}
