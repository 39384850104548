import { Component } from '@angular/core';

import { noop } from 'rxjs';
import { OktaPkceAuthService } from 'src/app/core/services/okta-pkce-auth.service';
import { UserService } from 'src/app/core/services/user.service';

import { RouteUtilService } from 'src/app/shared/utils/route-util.service';

import { Menu } from '../common';
import {
  OrderMenu,
  LogisticMenu,
  InventoryMenu,
  ReportMenu,
  AdministrationModule,
  LogoutMenu,
  SettingMenu,
  ContactUsMenu,
  HomeMenu,
} from '../common.constant';

@Component({
  selector: 'ndex-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  /*********************Properties*********************/
  readonly config = {
    notification: 4,
  };
  readonly menu = {
    home: HomeMenu,
    order: OrderMenu,
    logistic: LogisticMenu,
    inventory: InventoryMenu,
    report: ReportMenu,
    setting: SettingMenu,
    administration: AdministrationModule,
    contactUs: ContactUsMenu,
    logout: LogoutMenu,
  };

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(private routeUtils: RouteUtilService, private oktaService: OktaPkceAuthService, private userService: UserService) {}
  /*********************Constructor*********************/

  /*********************Component Methods*********************/

  onMenuClick(menu: Menu): void {
    this.resetMenu(menu);

    if (menu.url) {
      this.routeUtils.navigate(menu.url);
    }
  }

  onSubMenuClick(menu: Menu): void {
    if (menu.children) {
      menu.config.showChild = !menu.config.showChild;
    } else {
      menu.url ? this.routeUtils.navigate(menu.url) : noop();
      this.resetMenu();
    }
  }

  onSignOut() {
    this.oktaService.signOut();
  }

  isAdmin() {
    return this.userService.isAdmin();
  }

  closeMenu(): void {
    this.resetMenu();
  }

  /*********************Component Methods*********************/

  /*********************Private Methods*********************/

  private resetMenu(menu: Menu = null): void {
    Object.keys(this.menu)
      .filter((key) => !menu || key !== menu.name)
      .forEach((key) => {
        const parentMenu = this.menu[key] as Menu;
        parentMenu.config = { selected: false, showChild: false };
      });

    if (menu) {
      menu.config.showChild = !menu.children ? false : !menu.config.showChild;
      menu.config.selected = !menu.children ? false : !menu.config.selected;
    }
  }

  /*********************Private Methods*********************/
}
