import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CallbackComponent } from './common/callback/callback.component';
import { NoAccessComponent } from './common/no-access/no-access.component';

import { MasterRoutes, OrderRoutes } from './core/constants/route.constant';

const routes: Routes = [
  { path: '', redirectTo: OrderRoutes.OrderDetail.Name, pathMatch: 'full' },
  {
    path: MasterRoutes.Callback,
    component: CallbackComponent,
  },
  {
    path: MasterRoutes.NoAccess,
    component: NoAccessComponent
  },
  {
    path: MasterRoutes.Home,
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: MasterRoutes.Order,
    loadChildren: () =>
      import('./modules/order/order.module').then((m) => m.OrderModule),
  },
  {
    path: MasterRoutes.Logistics,
    loadChildren: () =>
      import('./modules/logistics/logistics.module').then(
        (m) => m.LogisticsModule
      ),
  },
  {
    path: MasterRoutes.Inventory,
    loadChildren: () =>
      import('./modules/inventory/inventory.module').then(
        (m) => m.InventoryModule
      ),
  },
  {
    path: MasterRoutes.Admin,
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: MasterRoutes.Reports,
    loadChildren: () =>
      import('./modules/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: MasterRoutes.Settings,
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: MasterRoutes.ContactUs,
    loadChildren: () =>
      import('./modules/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
