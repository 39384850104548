import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable } from 'rxjs/internal/Observable';

import { TokenService } from '../services/token.service';

import { HttpHeader } from '../constants/app.constant';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  /*********************Constructor*********************/
  constructor(private tokenService: TokenService) {}
  /*********************Constructor*********************/

  /*********************Implementation Methods*********************/

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add authorization token in header.
    const cloneRequest = request.clone({
      setHeaders: this.setAuthTokenHeader(request.headers),
    });

    // Pass on the cloned request instead of the original request.
    return next.handle(cloneRequest).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.verifyAuthToken(event);
        }
      })
    );
  }

  /*********************Implementation Methods*********************/

  /*********************Private Methods*********************/

  /**
   * Set authorization token for every http requests
   * @param headers Default Http Headers
   */
  private setAuthTokenHeader(headers: HttpHeaders) {
    const requestHeaders = {};
    const token = this.tokenService.get();

    if (token.accessToken) {
      requestHeaders[HttpHeader.Authorization] = token.tokenType + ' ' + token.accessToken; 
    }

    if (headers.keys().length > 0) {
      headers.keys().forEach((key) => {
        requestHeaders[key] = headers.get(key);
      });
    }

    return requestHeaders;
  }

  /**
   * Check for updated authorization token for every http response and update in cache.
   * @param response Http Service Response
   */
  private verifyAuthToken(response: HttpResponse<any>) {
    const accessTokenKey = response.headers
      .keys()
      .filter((key) => key.toLowerCase() === HttpHeader.Token.toLowerCase())[0];

    if (accessTokenKey) {
      this.tokenService.set(response.headers.get(accessTokenKey));
    }
  }

  /*********************Private Methods*********************/
}
