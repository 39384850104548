import { CacheResolverService } from '../cache/cache-resolver.service';

import { StaticBase } from './static-base';
import { ApplicationPreference } from './static';

export class AppPreference extends StaticBase {
  /*********************Properties*********************/
  private selectorPropertyName = 'preferenceId';
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private selectorName: string,
    private primaryKey: string,
    private storageCache: CacheResolverService
  ) {
    super(selectorName, primaryKey, storageCache);
  }
  /*********************Constructor*********************/

  /*********************Methods*********************/

  /**
   * Get app preference object by app preference key
   */
  get(key: string, defaultValue?: any): ApplicationPreference {
    const defaultPrefernce: ApplicationPreference = {
      preferenceId: key,
      preferenceValue: defaultValue,
      activeFlag: null,
      preferenceDesc: null,
      appTypeCode: null,
      loginFlag: false,
    };
    const preference = this.getItem(
      this.selectorPropertyName,
      key
    ) as ApplicationPreference;

    return preference || defaultPrefernce;
  }

  /**
   * Set app preference object
   */
  set(key: string, object: any): void {
    this.setItem(this.selectorPropertyName, key, object);
  }

  /*********************Methods*********************/
}
